import React from "react";

function Application(props) {
  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Application </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        
        <ul>
            <li><b>Person With Special Needs:</b> <span> {props.data.person_with_spcl_need} </span></li>
            <li><b>Type Of Disability:</b> <span> {props.data.type_of_disability} </span></li>
            <li><b>Position Applied For:</b> <span>{props.data.type_of_position_applied} </span></li>
            <li><b>Will you serve wherever you are posted?:</b> <span>{props.data.will_server_at_posted_place}</span></li>

        </ul>

      </div>

      
    </div>
  );
}

export default Application;
