import React from 'react'
import { BarChart } from '../Dashboard/Overseer/BarChart'

function Overseer() {
  return (
    <div className='bg-stone-900 h-full'>
        {/* <BarChart /> */}
    </div>
  )
}

export default Overseer
