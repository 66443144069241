import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import axios from "axios";
import { ContextData } from "../../context/Context";
import baseUrl from "../../../Route/BaseURL";
import IconView from "../FontIcons/IconView";
import ApplicantProfileTabs from "./ApplicantProfileTabs";
// import ApplicantProfileTabs from "../ApplicantProfile/ApplicantProfileTabs";
// import Avatar from "@mui/material/Avatar";

export default function ViewDialog(props) {


  

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [QualificationList, setQualificationList] = React.useState(null);
  const { AdministratorTokens, UpdateAmount } = React.useContext(ContextData);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');

  return (
    <div style={{maxWidth:"none !important", width:"80% !important"}}  className="appicant-profile-dialog-con bg-green-800">
      <Button variant="contained" onClick={handleClickOpen}>
        <IconView
          tooltipText="View Applicants Profile"
          tooltipColor="white"
          tooltipIconSize="19px"
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
         id="appicant-profile-dialog-con"
         fullWidth={fullWidth}
         maxWidth={maxWidth}
      >
        <DialogContent>
          <ApplicantProfileTabs type={props.type} data={props.data} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
