import React from 'react'
import Helper from '../Dashboard/AccountHelper/Helper'

function AccountHelper() {
  return (
    <div className='flex justify-center'>
      <Helper />
    </div>
  )
}

export default AccountHelper
