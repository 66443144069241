import React, { useContext, useEffect, useState } from "react";
import { IconButton, TextField } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import axios from "axios";
import baseUrl from "../../Route/BaseURL";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function SearchSection() {
  const [searchText, setSearchText] = useState("");
  const [Result, setResult] = useState(null);
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  /*   const OnSearch = ()=>{

    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/pub/get_awarded_applicant/${searchText}`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            //'authorization': `Bearer ${accesstoken}`
          },
        });


        if(response.data.status){
          setResult(response.data.data);

        }else{
          setResult(null)
        }
    
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();

  } */

  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(
          `${baseUrl}/pub/get_awarded_applicant/${searchText}`,
          {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              //'authorization': `Bearer ${accesstoken}`
            },
          }
        );

        if (response.data.status) {
          setResult(response.data.data);
        } else {
          setResult(null);
        }
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[searchText]);  


  const HandleDownload = (token)=>{


    
    async function FetchData() {
      try {
        // Make the API request and get the binary data (array buffer)
        const response = await axios.get(`${baseUrl}/pub/download-award/${token}`, {
          withCredentials: true,
          responseType: 'blob', // Important to get the response as a blob
          headers: {
            'Accept': 'application/pdf',
          },
        });
  
        // Create a new blob with the response data (PDF)
        const blob = new Blob([response.data], { type: 'application/pdf' });
  
        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'award_letter.pdf'; // Set the download file name
  
        // Append the link to the document and trigger the download
        document.body.appendChild(link);
        link.click();
  
        // Clean up: remove the link after triggering the download
        document.body.removeChild(link);
      } catch (err) {
        console.log('Error downloading the file:', err.message);
      }
    }
  
    FetchData();


  }
  


  return (
    <div className="search-section">
      <div className="heading mb-5">
        <h1 className="font-bold text-justify">
        The positions for SPOs,PO, and APO have been awarded. If you applied for the these positions, please enter one of the following details you used during registration: Application ID, email, or mobile number. The section below allows you to check if you have been selected. Simply input your Application ID, email, or mobile number, in the search bar, and a message will confirm your selection if applicable. Please note that all applicants are required to undertake the training within their respective local government area.
        </h1>
      </div>
          
        <p>Search Application By Application ID or By Mobile Number or By Email</p>
      <TextField
        className="search-input"
        label="Search By application ID, email, or mobile number"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
        fullWidth
        margin="normal"
      />
      <div className="bg-slate-50 p-4 h-40 rounded-lg overflow-auto srch-ul-con">
            {
              Result === null &&
              <p>No Result Found..</p>
            }
          {Result !== null && Result.map((list, index) => (
          <ul className="ver-aside-ul  p-3"> 
            <li className="flex justify-start items-center w-full li-srch" key={index} >
              <b style={{}} className="whitespace-nowrap">  <AccountCircleIcon /> {list.fullname}</b>
               <b style={{width:"13em"}} className="ml-5 whitespace-nowrap bg-green-950 text-white p-1 rounded-sm text-center ">Position: <b> {list.post.slice(list.post.indexOf("("))}</b></b>
               <b  style={{width:"13em"}}className="ml-5 whitespace-nowrap bg-blue-950 text-white p-1 rounded-sm  text-center ">Application ID:<b> {list.r_ID}</b></b> 
               {/* <p>{list.ref_tokens}</p> */}
            </li> 
               <p className="text-center w-full">Congratulations! You have been selected and awarded the position of {list.post} as part of the 2024 Adhoc staff for the Kaduna Independent Electoral Commission <p onClick={()=>HandleDownload(list.r_ID)} className="text-blue-950 cursor-pointer"> <p> Click the link to download your award letter and Print it out</p></p></p>
        </ul> 
          ))}
      </div>

        <p className="font-bold text-justify my-3 ">Please note that all applicants are required to undertake the training within their respective local government area.</p>
   {/*      <p className="font-bold text-justify my-3 ">All applicants are required to attend the mandatory training session at the designated venue corresponding to the zone their local government falls under. Please ensure you confirm your local government area and head to the appropriate venue within your zone on the scheduled training date. Kindly find your venue from the list below</p> */}
 {/*      <div className="grid-container">
        <div className="grid-item">
          <h1 className="">Applicants From Zone 1A</h1>
          <ol className="text-left px-2 my-2">
            <li>Kaduna North</li>
            <li>Kaduna South</li>
            <li>Chikun</li>
            <li>Kajuru</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Magajin gari, SIECOM Office
          </p>
        </div>
        <div className="grid-item">
          <h1 className="">Applicants From Zone 1B</h1>
          <ol className="text-left px-2 my-2">
            <li>Birnin Gwari</li>
            <li>Giwa</li>
            <li>Igabi</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Rigachukun
          </p>
        </div>
        <div className="grid-item">
          <h1 className="">Applicants From Zone 2A</h1>
          <ol className="text-left px-2 my-2">
            <li>Zaria</li>
            <li>Sabon Gari</li>
            <li>Soba</li>
            <li>Kudan</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Barewa College, Zaria
          </p>
        </div>
        <div className="grid-item">
          <h1 className="">Applicants From Zone 2B</h1>
          <ol className="text-left px-2 my-2">
            <li>Makarfi</li>
            <li>Ikara</li>
            <li>Kubau</li>
            <li>Lere</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Ikara
          </p>
        </div>
        <div className="grid-item">
          <h1 className="">Applicants From Zone 3A</h1>
          <ol className="text-left px-2 my-2">
            <li>Kagarko</li>
            <li>Kachia</li>
            <li>Kauru</li>
            <li>Zangon Kataf</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Kachia
          </p>
        </div>
        <div className="grid-item">
          <h1 className="">Applicants From Zone 3B</h1>
          <ol className="text-left px-2 my-2">
            <li>Jaba</li>
            <li>Jama'a</li>
            <li>Kaura</li>
            <li>Sanga</li>
          </ol>
          <p className="text-left">
            <strong>Venue:</strong> Kafanchan
          </p>
        </div>
      </div> */}
    </div>
  );
}

export default SearchSection;
