import React, { useContext, useEffect, useState } from "react";
import { TextField,Button } from "@mui/material";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";
import Loader from "../../../Route/Loader";

function BankDetailsStep() {


  const {Person,ReloadPersonData,setReloadPersonData,accesstoken} = useContext(ContextData)
  const [formData, setFormData] = useState({
    bankName: Person !== null && Person.bank_details[0].bank_name || " ",
    accountNumber: Person !== null && Person.bank_details[0].account_name || " ",
    accountName: Person !== null && Person.bank_details[0].account_number || " "
  });
  const check = Person !== null && Person.applicant[0].submit_status
  
  const [OpenLoader,setOpenLoader] = useState(false)

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




  const HandleUpdate = ()=>{
   
    setOpenLoader(true)
    let ref_tokens = Person !== null && Person.applicant_edu[0].ref_tokens
    let data = {...formData,ref_tokens}
     
    axios.put(`${baseUrl}/auth/update_bank`,data,{
      withCredentials: true,
      headers: {
        'Accept': "application/json",
        'authorization': `Bearer $${accesstoken !== null && accesstoken}`
      },
    }).then(res=>{ 
             
      toast.success(res.data)
      setReloadPersonData(!ReloadPersonData)
      setOpenLoader(false)
     })
    .catch((err) => {
      if (err) return console.log(err.message);
      setOpenLoader(false)
    })

  }
 

   

  return (
    <div className="outer-con h-fit">

      <Loader
       OpenLoader={OpenLoader}
      />
      <div className="form-con">
        <form>
          <TextField
            label="Bank Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.bankName}
            onChange={handleInputChange("bankName")}
            //onDoubleClick={HandleUpdate}
            disabled={check === "Agreed" ? true : false}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={HandleUpdate}
                  disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
              )
            }}
          />
           <p className="text-green-900 font-bold italic text-sm">
            *Click to save Details
          </p>
          <TextField
            label="Account Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.accountNumber}
            onChange={handleInputChange("accountNumber")}
           // onDoubleClick={HandleUpdate}
            disabled={check === "Agreed" ? true : false}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={HandleUpdate}
                  disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
              )
            }}
          />
           <p className="text-green-900 font-bold italic text-sm">
            *Click to save Details
          </p>
          <TextField
            label="Account Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.accountName}
            onChange={handleInputChange("accountName")}
           // onDoubleClick={HandleUpdate}
            disabled={check === "Agreed" ? true : false}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={HandleUpdate}
                  disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
              )
            }}
          />
          <p className="text-green-900 font-bold italic text-sm">
            *Click to save Details
          </p>
        </form>
      </div>
    </div>
  );
}

export default BankDetailsStep;
