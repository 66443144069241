import { Button, TextField } from "@mui/material";
import React, { useState } from "react";

function SectionOne() {
    const [formData, setFormData] = useState({
        heading: "",
        mainContent: "",
      });
    
      const handleInputChange = (field) => (e) => {
        setFormData({ ...formData, [field]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
        // Check for empty fields
        const isEmptyField = Object.values(formData).every((value) => value === "");
    
        if (isEmptyField) {
          alert("Please fill out all fields");
        } else {
          console.log(formData);
        }
      };
  return (
    <div className="my-2 site-form-con ">
      <form onSubmit={handleSubmit}>
        <div className="input-con">
          <TextField
            label="Heading"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            margin="normal"
            value={formData.heading}
            onChange={handleInputChange("heading")}
          />
          <TextField
            label="Main Content"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            margin="normal"
            value={formData.mainContent}
            onChange={handleInputChange("mainContent")}
          />
          <Button variant="contained" color="primary">
            Publish
          </Button>
        </div>
      </form>
    </div>
  )
}

export default SectionOne
