import React, { useState, useEffect, useContext } from "react";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import PopUpDialog from "./PopUpDialog";
import { FaQuoteRight } from "react-icons/fa";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { BsSlashLg } from "react-icons/bs";
import { ContextData } from "../context/Context";
import SPOList from "./SPOList";
import POList from "./POList";
import APOList from "./APOList";
import PCList from "./PCList";
// import { motion } from "framer-motion";
//import axios from "axios";

function Review() {


  const [FoundList, setFoundList] = useState(null);
  const [PositionApllied, setPositionApllied] = useState("");

  const { setCheckNull } = useContext(ContextData);
  const { CheckEligibility, setCheckEligibility,setIam_Applying_for,OpenAppstatus } = useContext(ContextData);
  

  const handleInputChange = (e) => {
    setPositionApllied(e.target.value);
  };

  const Positions = [
    {
      position: "Supervisory Presiding Officer (SPO)",
      text: <SPOList />,
    },

    {
      position: "Presiding Officer (PO)",
      text: <POList />,
    },

    {
      position: "Assistant Presiding Officer (APO)",
      text: <APOList />,
    },

    {
      position: "Poll Clerk(PC)",
      text: <PCList />,
    },
  ];

  useEffect(() => {
    let finded = Positions.find((list) => list.position === PositionApllied);

    if (finded) {
      setFoundList(finded.text);
      setIam_Applying_for(finded.position)
      setCheckNull("auto");
    } else {
      setFoundList(null);
      setIam_Applying_for("")
      setCheckNull("100vh");
    }
  }, [PositionApllied]);

  return (
    <div
      className="review-con"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, paddingTop: "6em" }}
      exit={{ opacity: 0, transition: { duration: 0.1 }, paddingTop: "0em" }}
    >
      <div className="outer-con h-fit ">


      {
        OpenAppstatus !== null && 
        
        <> 

            {
              OpenAppstatus === 1 ? 
       <div className="form-con bg-slate-100">
          <h1 className="text-3xl text-red-400 mb-2">Check Eligibility</h1>
          <form>
            <InputLabel id="position-input">
              Position You're Applying For
            </InputLabel>
            <Select
              label="Position You're Applying For"
              labelId="position"
              id="position-input"
              fullWidth
              value={PositionApllied}
              onChange={handleInputChange}
            >
              {Positions.map((pos, index) => (
                <MenuItem key={index} value={pos.position}>
                  {pos.position}
                </MenuItem>
              ))}
            </Select>

            {FoundList !== null && (
              <div>
                <h1 className="text-3xl text-red-400 mt-4">
                  Eligibility Conditions
                </h1>
                <div
                  className="text-justify text-black"
                  style={{ whiteSpace: "pre-line" }}
                >
                  {FoundList !== null && FoundList}
                </div>
                

                <div className="checkbox_con flex justify-center items-center gap-2 text-black">
                  <input
                    type="checkbox"
                    className="checkbox_btn"
                    checked={CheckEligibility}
                    onChange={(e) => setCheckEligibility(e.target.checked)}
                  />
                  <label>Yes, I am Eligible for this position</label>
                </div>
                <div className="w-full flex justify-center items-center">
                  <PopUpDialog />
                </div>
              </div>
            )}
          </form>

        </div>  

            : 
               <div className="w-full flex justify-center items-start h-3/5">
                  <PopUpDialog />
                </div>

            }    
            
        </>
      }


      </div>
    </div>
  );
}

export default Review;
