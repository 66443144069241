import React, { useContext, useEffect, useState } from "react";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import axios from "axios";
import baseUrl from "../../../Route/BaseURL";
import NigerianStates from "../../Portal/NigerianStates";
import { ContextData } from "../../context/Context";
import { Alert,Button ,TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,} from "@mui/material";
// import { ContextData } from '../../context/Context';
// import Loader from '../../routes/Loader';

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "auto",
    padding: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function Helper(props) {
    
  const classes = useStyles();

  // const {setUpdateAmount,UpdateAmount} = useContext(ContextData)
  const { Iam_Applying_for, accesstoken,PersonUser} = useContext(ContextData);
  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [proceedForm, setproceedForm] = useState(true);
  const [OpenLoader, setOpenLoader] = useState(false);
  const [FoundedWards, setFoundedWards] = useState(null);
  const [WardStatus, setWardStatus] = useState(true);
  const [local_gov, setlocal_gov] = useState("");
  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobileNumber: "",
    gender: "",
    d_o_b: "",
    state_of_origin: "Kaduna",
    ward: "",
    pswrd: "12345",
    rpt_pswrd: "12345",
    position_applying: "",
    submitted_by_email: PersonUser.email || "",
    submitted_fullname_by: `${PersonUser.fname} ${PersonUser.lname}` || "",
    type:"account_helper",
    bankName : "",
    AccountName: "",
    AccountNumber: ""
  });
  const [newLocalGovts, setNewLocalGovts] = useState(null);
  const [WardLists, setWardLists] = useState(null); 

  const [Member, setMember] = useState("");

  const [EnableQualified, setEnableQualified] = useState(false);

  const handleMemberChange = (e) => {
    setMember(e.target.value);
  };

  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/pub/get_local_gov`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            authorization: `Bearer ${accesstoken}`,
          },
        });

        setNewLocalGovts(response.data);
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  }, []);

  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/pub/get_wards`, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            authorization: `Bearer ${accesstoken}`,
          },
        });

        setWardLists(response.data);
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle the alert transition and function
  const handleClick = (Transition) => {
    setTransition(() => "TransitionLeft");
    setOpen(true);
  };
  // Handle the close alert box
  const handleClose = () => {
    setOpen(false);
  };

  // Handle data to server if succeed
  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(formData).every((value) => value.trim() === "")) {
      setmessage("Please fill in all required fields.");
      toast.warn("Please fill in all required fields.");
      return;
    }

     if(formData.email !== "null" ){

       if (!validator.isEmail(formData.email)) {
         toast.warn("Email address is not a valid");
         return;
       } 

     }


    if (!validator.isNumeric(formData.mobileNumber)) {
      toast.warn("Mobile Number is not a valid");
      return;
    }

    if (formData.pswrd !== formData.rpt_pswrd) {
      toast.warn("Passwords is not match");
      return;
    }

    //  console.log(formData)

    // loader
    const data = { ...formData, local_gov };
    setOpenLoader(true);

    axios
      .post(`${baseUrl}/pub/up`, data)
      .catch((err) => {
        console.log(err.message);
        // loader
        setOpenLoader(false);
      })
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.textStatus);
          // loader
          setOpenLoader(false);
        }

        if (!res.data.status) {
          toast.warn(res.data.textStatus);
          // loader
          setOpenLoader(false);
        }
      });
  };

  const proceedFormFunc = () => {
    setproceedForm(false);
  };

  const handlLocal_gov_Change = (e) => {
    const local_gov = e.target.value;

    setlocal_gov(local_gov);
  };

  useEffect(() => {
    let findedWards =
      WardLists !== null &&
      WardLists.filter((lga) => lga.add_local_gov === local_gov);

    if (findedWards) {
      setFoundedWards(findedWards);
    } else {
      setFoundedWards(null);
    }
  }, [local_gov]);

  const position = [
    "Supervisory Presiding Officer (SPO)",
    "Presiding Officer (PO)",
    "Assistant Presiding Officer (APO)",
    "Poil Clerk(PC)",
  ];

  return (
    <form
      className={`${classes.form}clnt-register-form flex justify-center flex-col bg-slate-100 p-5 my-6`}
      style={{ width: "35rem" }}
      onSubmit={handleSubmit}
      id="clnt-register-form"
    >
         <h1 className="text-3xl text-center mb-1 font-extrabold">Account Helper</h1>

      <div className="flex justify-center flex-col innerform-inputs-con">
        <TextField
          label="First Name"
          name="fname"
          value={formData.fname}
          onChange={handleInputChange}
          required
          className={classes.inputField}
        />
        <TextField
          label="Last Name"
          name="lname"
          value={formData.lname}
          onChange={handleInputChange}
          required
          className={classes.inputField}
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
          className={classes.inputField}
        />
        <TextField
          label="Valid Mobile Number"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleInputChange}
          required
          className={classes.inputField}
        /> 

        <FormControl className={classes.selectField}>
          <InputLabel sx={{marginBottom:"1em"}}>Position applying for</InputLabel>
          <Select
            name="position"
            value={formData.position_applying}
            onChange={(e) => setFormData({...formData, position_applying:e.target.value}) }
            required
          >
            {position.map((pos) => (
              <MenuItem value={pos}>{pos}</MenuItem>
            ))}
          </Select>
        </FormControl>

        

        <FormControl className={classes.selectField}>
          <InputLabel sx={{marginBottom:"1em"}}>State of Origin</InputLabel>
          <Select
            name="state_of_origin"
            value={formData.state_of_origin}
            onChange={handleInputChange}
            disabled={formData.resdential_type === "indigene"}
            required
          >
            {NigerianStates.map((list) => (
              <MenuItem value={list}>{list}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.selectField}>
          <InputLabel>Local Government</InputLabel>
          <Select
            name="local_gov"
            value={formData.local_gov}
            onChange={handlLocal_gov_Change}
            required
          >
            {newLocalGovts !== null &&
              newLocalGovts.map((list) => (
                <MenuItem value={list.add_local_gov}>
                  {list.add_local_gov}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl className={classes.selectField}>
          <InputLabel>Ward</InputLabel>
          <Select
            name="ward"
            value={formData.ward}
            onChange={handleInputChange}
            required
          >
            {FoundedWards !== null &&
              FoundedWards.map((list, index) => (
                <MenuItem key={index} value={list.add_ward_gov}>
                  {list.add_ward_gov}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl className={classes.selectField}>
          <InputLabel>Gender</InputLabel>
          <Select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required
          >
            <MenuItem value="Select">Select</MenuItem>
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </FormControl>

        {/* <span>Date OF Birth</span>
        <TextField
          label=""
          name="d_o_b"
          value={formData.d_o_b}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="date"
        /> */}
      </div>
      <div className="flex flex-col">
          <h2 className="text-3xl font-extrabold text-center m-3">Bank Details</h2>
        <TextField
          label="Bank Name"
          name="bankName"
          value={formData.bankName}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="text"
        />
         <TextField
          label="Account Name"
          name="AccountName"
          value={formData.AccountName}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="text"
        />
         <TextField
          label="Account Number"
          name="AccountNumber"
          value={formData.AccountNumber}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="text"
        />

      
      </div>

      <div className="flex flex-col">

      <h2 className="text-3xl font-extrabold text-center m-3">Credentials</h2>

        <TextField
          label="Password"
          name="pswrd"
          value={formData.pswrd}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="password"
        />

        <TextField
          label="Repeart Password"
          name="rpt_pswrd"
          value={formData.rpt_pswrd}
          onChange={handleInputChange}
          required
          className={classes.inputField}
          type="password"
        />
      </div>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Sign Him / Her
      </Button>

      <br />
    </form>
  );
}

export default Helper;
