import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button
} from "@mui/material";
import { ContextData } from "../../context/Context";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Route/Loader";



function WorkExperienceStep() {
 


  const {Person,ReloadPersonData,setReloadPersonData,accesstoken} = useContext(ContextData)
  const [OpenLoader,setOpenLoader] = useState(false)
  const check = Person !== null && Person.applicant[0].submit_status

  const [formData, setFormData] = useState({
    yearsOfExperience:
      (Person !== null && Person.applicant_wrk_experience[0].years_of_wrk) ||
      "",
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };






  const handleupdate = () => {

    setOpenLoader(true)
      let ref_tokens =
      Person !== null && Person.applicant_wrk_experience[0].ref_tokens;
    

    axios
      .put(
        `${baseUrl}/auth/update_wrk_exprn/${formData.yearsOfExperience}/${ref_tokens}`,
        {
          withCredentials: true,
          headers: {
            'Accept': "application/json",
             'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          },
        }
      )
      .then((res) => {
        setOpenLoader(false)
        toast.success(res.data);
      })
      .catch((err) => {
        setOpenLoader(false)
        if (err) return console.log(err.message);
      });
  };

  return (
    <div className="outer-con h-fit">

    <Loader
       OpenLoader={OpenLoader}
      /> 
      <div className="form-con">
        <form>
          <TextField
            label="Years of Working Experience"
            variant="outlined"
            fullWidth
            margin="normal"
            type="number"
            value={formData.yearsOfExperience}
            onChange={handleInputChange("yearsOfExperience")}
            onDoubleClick={handleupdate}
            disabled={check === "Agreed" ? true : false}
            InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleupdate}
                  disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
              )
            }}
          />
          <p className="text-green-900 font-bold italic text-sm">
            Click to save Details
          </p>
        </form>
      </div>
    </div>
  );
}

export default WorkExperienceStep;
