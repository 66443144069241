import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Context from "./components/context/Context";
import Router from "./Route/Router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <div className="App">
        <ToastContainer position="top-center" />
     
      <BrowserRouter>
        <Context>
          <Router />
        </Context>
      </BrowserRouter>
    </div>
  );
}

export default App;
