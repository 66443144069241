
// const baseUrl = "http://127.0.0.1:8700"
 const baseUrl = "https://kadsiecom.mmt-ng.com"
// "http://127.0.0.1:8700"
//https://kadsiecom.mmt-ng.com"
//http://mmthost.com:5000
// get_all_not_sub_applicant
// abdulazeezibrahim202@gmail.com
// 36366360

module.exports =  baseUrl 