import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function Contact() {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    fullName: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // Check for empty fields
  //   const isEmptyField = Object.values(formData).some((value) => value === "");

  //   if (isEmptyField) {
  //     alert("Please fill out all fields");
  //   } else {
  //     // Submit the form (you can implement your submission logic here)

  //     console.log(formData);

  //     //  console.log("Form submitted:", );
  //     setFormData({
  //       fullName: "",
  //       subject: "",
  //       message: "",
  //     });
  //   }
  // };

  return (
    <div className="contact_section-con">
      <div className="outer-con h-fit">
        <div className="form-con bg-slate-100 rounded-md">
          <h1 className="text-3xl">Contact Us</h1>
          <form
            action="https://formsubmit.co/kadsiec@yahoo.com"
            method="POST"
          >
            <TextField
              label="Full Name"
              name="Full Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.fullName}
              onChange={handleInputChange("fullName")}
            />
            <TextField
              label="Subject"
              name="Subject"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.subject}
              onChange={handleInputChange("subject")}
            />
            <TextField
              label="Message"
              name="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              margin="normal"
              value={formData.message}
              onChange={handleInputChange("message")}
            />
            <div className=" font-bold mt-5 flex justify-center items-center">
              <button
                type="submit"
                className="btn btn-primary text-gray-50 bg-gray-900 p-2 font-bold rounded-md"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
