import React, { useContext, useEffect, useState } from "react";
import {
  TextField,
  Button,
} from "@mui/material";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";




function OpenApplication() {





 const {accesstoken} = useContext(ContextData)
 const [status,setstatus] = useState(null)
 const [Reload,setReload] = useState(true)

 
  const handleOpen = (value) => {
   
         
        const data = {value}

   
    axios.put(`${baseUrl}/auth/open_application`,data,{
      withCredentials: true,
      headers: {
        Accept: "application/json",
         'authorization': `Bearer ${accesstoken}`
      },
    })
    .then((res) => {
    
          toast.success(res.data)
         setReload(!Reload)
         
    })
    .catch((err) => {
      if (err) return console.log(err.message);
    });


  };


  
  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_open_application`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setstatus(response.data[0].open_status);      
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 





  return (
    <div className="add-localGovt">
     

       <h1 className="text-center font-extrabold text-2xl">Open Application</h1>
         
        
         
         {
           status !== null && <>
            

             {
              status === 0 ?  
              <>
               <p>The recruitment application is Closed click the button to open application: </p>
              <Button variant="contained" color="primary" onClick={()=>handleOpen(1)}>
               Open  Application
              </Button>
               </>
                :
                <>
               
                 <p>The recruitment application is open Click the button to Closed the application: </p>
                <Button variant="contained" color="primary" onClick={()=>handleOpen(0)}>
                  Close Application 
                </Button>
                
                 </>
             }
           
           
           </>
         }
       

    
    </div>
  );
}

export default OpenApplication;
