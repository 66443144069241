import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Avatar, Button } from "@mui/material";
import BioData from "./BioData";
import Demography from "./Demography";
import Education from "./Education";
import WorkExperience from "./WorkExperience";
import Application from "./Application";
import BankDetails from "./BankDetails";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SchoolIcon from "@mui/icons-material/School";
import { InputLabel, MenuItem, Select } from "@mui/material";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";
import PostDialog from "../Post/PostDialog";


export default function ApplicantProfileTabs(props) {

  
  const {accesstoken,ReloadApplicant_At_Filter,setReloadApplicant_At_Filter,Checker,PersonUser} = React.useContext(ContextData)

  const [value, setValue] = React.useState("1");
  const [formData, setFormData] = React.useState({ 
            positionApllied: props.data.type_of_position_applied || ""
        })


  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };


  const HandleUpdatePost = () =>{

          
      const data = {post: formData.positionApllied,token:props.data.user_token,r_ID:  props.data.r_ID,}

      axios.put(`${baseUrl}/auth/update_post`,data,{
        withCredentials: true,
        headers: {
          'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      }).then(res=>{ 
                 
        toast.success(res.data)
       
       })
      .catch((err) => {
        if (err) return console.log(err.message);
     ///   setOpenLoader(false)
      })

      
   
  }

  const position = [
    "Supervisory Presiding Officer (SPO)",
    "Presiding Officer (PO)",
    "Assistant Presiding Officer (APO)",
    "Poil Clerk(PC)",
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


    const HandleProcess = (lga,choose,user_token)=>{

        const data = {
          lga,
          choose,
          user_token,
          fullname: props.data.fname+ " " + props.data.lname,
          email : props.data.email,
          mobile: props.data.mobile,
          ward: props.data.applcnt_ward,
          r_ID:  props.data.r_ID,
          post : props.data.type_of_position_applied,
          selected_by: PersonUser.email
        }



      axios.put(`${baseUrl}/auth/selection_process`,data,{
        withCredentials: true,
        headers: {
          'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      }).then(res=>{ 
                 
        toast.success(res.data)
        setReloadApplicant_At_Filter(!ReloadApplicant_At_Filter)

       })
      .catch((err) => {
        if (err) return console.log(err.message);
     ///   setOpenLoader(false)
      })
  
    

    }



  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      {/* Profile preview section */}
      <div className="flex justify-between items-center w-full p-3">
        <div className="preview-con flex  w-full p-3">
          <div className={props.sty === "sty" ? "w-1/6" : "w-1/6"}>
            <Avatar
              alt="P"
              src={`${baseUrl}/get_pic/${props.data.picture}`}
              sx={{ width: 100, height: 100 }}
            />
          </div>
          <div className=" w-3/6 ">
        
            <p>
              <b>Name:</b> <span>{props.data.fname + " " + props.data.lname}</span>
            </p>
            <p>
              <b>Email:</b> <span>{props.data.email}</span>
            </p>
            <p>
              <b>State Of Origin:</b> <span>{props.data.applcnt_state_of_origin}</span>
            </p>
            <p>
              <b>LGA:</b> <span>{props.data.local_gov}</span>
            </p>
            <p>
              <b>Mobile No:</b> <span>{props.data.mobile}</span>
            </p>
            <p>
            <p>
              <b>Position Applied:</b>
            </p>
            <p className="flex whitespace-nowrap"><p> <Select
            label="Position Applied For"
            labelId="position"
            id="position-input"
            disabled={props.type === "post" ? true : false}
            value={formData.positionApllied}
            onChange={handleInputChange("positionApllied")}
          //  onDoubleClick={handleupdate}
         //   disabled={check === "Agreed" ? true : false}
          >
            {position.map((pos, index) => (
              <MenuItem key={index} value={pos}>
                {pos}
              </MenuItem>
            ))}
            </Select>{props.type === "post" ? "" : <Button onClick={HandleUpdatePost} sx={{marginLeft:"1em"}}>Update</Button>}</p></p>

            </p>

           
          </div>
          <div>
             <p> <b>Application ID : </b> <b>{props.data.r_ID}</b></p>
               <p><b>From :</b> {props.data.applcnt_ward} ward</p>
               <hr/>
               <br/>
               <hr/>
               {
                props.data.posted_ward !== null &&
             <p> <b>Ward Posted to is :</b> {props.data.posted_ward}</p>
               }
               {
                props.data.posted_to !== null &&
             <p> <b>Unit Posted to is :</b> {props.data.posted_to}</p>
               }
          </div>
        </div> 

        {props.sty !== "sty" && (
          <div className="buttons flex gap-2 h-8">
                 {
                  props.type === "post" ? 
                  <PostDialog data={props.data} /> :
                  <>
            <Button onClick={()=>HandleProcess(props.data.local_gov,1,props.data.user_token)} variant="contained">Select</Button>
             {
              Checker !== null && 
              <> 
                {
                  Checker === "Administrator" && 
            <Button onClick={()=>HandleProcess(props.data.local_gov,2,props.data.user_token)} variant="contained" color="error">
              Reject
            </Button>
                }
              
              </>
             }
                  </>
                 }

          </div>
        )} 
      </div>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Bio Data"
              value="1"
              icon={<FolderSharedIcon />}
              iconPosition="start"
            />
            <Tab
              label="Demography"
              value="2"
              icon={<LocationOnIcon />}
              iconPosition="start"
            />
            <Tab
              label="Education"
              value="3"
              icon={<SchoolIcon />}
              iconPosition="start"
            />
            <Tab
              label="Work Experience"
              value="4"
              icon={<WorkIcon />}
              iconPosition="start"
            />
            <Tab
              label="Application"
              value="5"
              icon={<EditNoteIcon />}
              iconPosition="start"
            />
            {
              props.type !== "post" && 
            <Tab
              label="Bank Details"
              value="6"
              icon={<MonetizationOnIcon />}
              iconPosition="start"
            />
            }
          </TabList>
        </Box>
        <TabPanel value="1">
          <BioData 
           data={props.data}
          />
        </TabPanel>
        <TabPanel value="2">
          <Demography 
           data={props.data}
          />
        </TabPanel>
        <TabPanel value="3">
          <Education 
           data={props.data}
          />
        </TabPanel>
        <TabPanel value="4">
          <WorkExperience 
           data={props.data}
          />
        </TabPanel>
        <TabPanel value="5">
          <Application 
           data={props.data}
          />
        </TabPanel>
        <TabPanel value="6">
          <BankDetails 
           data={props.data}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
