import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import BioData from "../Dashboard/Profile/BioDataStep";
import DemographyStep from "../Dashboard/Profile/DemographyStep";
import EducationStep from "../Dashboard/Profile/EducationStep";
import WorkExperienceStep from "../Dashboard/Profile/WorkExperienceStep";
import ApplicationStep from "../Dashboard/Profile/ApplicationStep";
import BankDetailsStep from "../Dashboard/Profile/BankDetailsStep";
import { ContextData } from "../context/Context";
import AcknowledgementStep from "../Dashboard/Profile/AcknowledgementStep";
import axios from "axios";
import baseUrl from "../../Route/BaseURL";
import ProgressLoader from "./ProgressLoader";

const steps = [
  "Bio Data",
  "Demography",
  "Education",
  "Work Experience",
  "Application",
  "Bank Details",
  "Acknowledgement",
];

export default function Profile() {
  
  const [activeStep, setActiveStep] = React.useState(0);
  //const [] = React.useState(null)
  const [completed, setCompleted] = React.useState({});
  const {Person,setPerson,setReloadPersonData,ReloadPersonData,ApplicantTokens,accesstoken,OpenAppstatus} = useContext(ContextData)

  
  // Get Client Data

  useEffect(()=>{
            

    async function FetchData(){
          
    
        try {
            
          const response = await axios.get(`${baseUrl}/auth/get_applicant_data/${ApplicantTokens}`,{
            withCredentials: true,
             headers: {
              'Accept' : 'application/json',
              'Content-Type': 'application/json',
              // 'authorization': `Bearer ${accesstoken !== null && accesstoken}`
            }
            } 
            ) 
       
           setPerson(response.data)
          /// console.log(response.data)   
           
       } catch (err) {
         if(err) console.log(err.message)
       }
    
      }
      
      FetchData() 
  
   },[ReloadPersonData,ApplicantTokens,accesstoken])

  const [formData, setFormData] = useState({
    surname: "",
    otherNames: "",
    dob: "",
    gender: "",
  });

  useEffect(()=>{

    setReloadPersonData(!ReloadPersonData)
           
  },[])

  const gender = ["male", "female"];

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%", marginTop: "3rem" }}>
       <h3 className="text-center mb-4" > 
        <p className="pl-1 font-extrabold">
              Application  ID : {Person !== null && Person.applicant[0].r_ID}
            </p></h3>

          { // 0 
          (OpenAppstatus !== null && OpenAppstatus === 0) && <ProgressLoader/>
          }

          { // 0
          (OpenAppstatus !== null && OpenAppstatus === 1) && 
          <> 
      <Stepper className="profile_steps_line" sx={{width: "70%",whiteSpace:"nowrap",left:"50%",position:"absolute",transform:"translate(-50%,-40%)"}} nonLinear activeStep={activeStep}>
        
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step> 
        ))}

       
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* const steps = ['Bio Data', 'Demography', 'Education', 'Work Experience', 'Application', 'Bank Details']; */}
           
            <div sx={{ mt: 2, mb: 1, py: 1 }}>
              {activeStep + 1 === 1 && <BioData />}
              {activeStep + 1 === 2 && <DemographyStep />}
              {activeStep + 1 === 3 && <EducationStep />}
              {activeStep + 1 === 4 && <WorkExperienceStep />}
              {activeStep + 1 === 5 && <ApplicationStep />}
              {activeStep + 1 === 6 && <BankDetailsStep />}
              {activeStep + 1 === 7 && <AcknowledgementStep />}
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep + 1 !== 7 && <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>}
              
             
            </Box>
          </React.Fragment>
        )}
      </div>
          
          </>
        }

           
    </Box>
  );
}
