import React, { useContext, useState } from 'react';
import validator from 'validator'
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlertPOP from '../../Route/AlertPOP';
import axios from 'axios';
import baseUrl from '../../Route/BaseURL';
import { ContextData } from '../context/Context';
import Loader from '../../Route/Loader';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const useStyles = makeStyles((theme) => ({

  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    margin: 'auto',
    padding: theme.spacing(2),
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  selectField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));




function ApplicantLogin(props){


  const classes = useStyles()
  const {setApplicantTokens,setaccesstoken,setChecker,ReloadPersonData,setReloadPersonData} = useContext(ContextData)
  const [message,setmessage] = useState("")
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [proceedForm, setproceedForm] = useState(true);
  const [OpenLoader,setOpenLoader] = useState(false)
  const Navigate = useNavigate()

  const [formData, setFormData] = useState({
    email: '',
    userpassword: ''
  });
 





     
     const handleInputChange = (e) => {
       
       const { name, value } = e.target;
       setFormData((prevData) => ({ ...prevData, [name]: value }));
       
      }


     // Handle the alert transition and function
     const handleClick = (Transition) => {
      setTransition(() => "TransitionLeft")
       setOpen(true);
     };
     // Handle the close alert box 
     const handleClose = () => {
       setOpen(false);
     };

 
      // Handle data to server if succeed
 const handleSubmit = (e) => {
    
        e.preventDefault()
    
    if (Object.values(formData).every((value) => value.trim() === '')) {
               // Call alert box function
        handleClick("left")
          setmessage('Please fill in all required fields.');
          return
      }

    if(!validator.isEmail(formData.email)){

      handleClick("left")

      setmessage("Email address is not a valid");
      return
    }

          // loader
          setOpenLoader(true)

          axios.post(`${baseUrl}/pub/login_applicant`,formData)
          .catch(err => { 
            console.log(err.message) 
                // loader
                setOpenLoader(false)
          })
          .then((res) =>{  
             
  
                     if(res.data.status){
  
                       setChecker("applicant")
                       handleClick("left")
                       setmessage(res.data.textStatus+"...");  
                       setApplicantTokens(res.data.Usertoken) 
                       setaccesstoken(res.data.accessToken)
                       toast.success(res.data.textStatus+"...")
                       setReloadPersonData(!ReloadPersonData)
                      
             
                       // loader 
                       setOpenLoader(false)


                     const timer = setTimeout(()=>{
                                
                      Navigate("/application/applicantlandpage")  
                               clearTimeout(timer)
                        },200) 
  
  
                     } 
  
                     if(!res.data.status){
  
                      handleClick("left")
                      setmessage(res.data.textStatus); 
                      toast.warn(res.data.textStatus);  
                          // loader
                      setOpenLoader(false)
 
                    }
                    

  
          })







  }



 const proceedFormFunc = ()=>{

  setproceedForm(false)
  
 }


  // Handle resdential function
  const handleResidentialTypeChange = (e) => {

    const selectedResidentialType = e.target.value

    setFormData((prevData) => ({
      ...prevData,
      resdential_type: selectedResidentialType,
      state_of_origin: selectedResidentialType === 'indigene' ? 'Kaduna' : 'Select State'
    }))

  }







  return (
    <form className={`${classes.form}clnt-register-form h-96 flex flex-col pb-16`} onSubmit={handleSubmit} id="clnt-login-form">

       <AlertPOP
       Open={open}
       handleClose={handleClose}
       transition={transition}
       message={message}
      />

       <Loader
       OpenLoader={OpenLoader}
      />

       <div className='flex justify-center'>

      <img src='./assets/siecom.png' className='w-44 mb-3 ' />
       </div>
      <div className='flex flex-col'>

      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
        className={classes.inputField}
      />
          
        <TextField
        label="Password"
        name="userpassword"
        value={formData.userpassword}
        onChange={handleInputChange}
        required
        className={classes.inputField}
        type='password'
        />


      </div>
    
      <Button type="submit"  variant="contained" color="primary" className={classes.button}>
        Login
      </Button>
        {/* <span className='text-center p-2 font-extrabold text-cyan-950'>Forgotten password ?</span> */}
      <br/>

    </form>
  );
};

export default ApplicantLogin;