import React, { useContext, useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import AwardBatchAccordion from "../Dashboard/Award/AwardBatchAccordion";
import { Button, IconButton, TextField } from "@mui/material";
import AwardDialog from "../Dashboard/Award/AwardDialog";
import kadunaLocalGovernments from "../Portal/kadunaLocalGovernments ";
import baseUrl from "../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../context/Context";

function Award() {


  const [searchText, setSearchText] = useState("");
  const {accesstoken} = useContext(ContextData)
  const [NewLocalGovts,setNewLocalGovts] = useState(null)

    
  useEffect(() => {

    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[]); 


  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };



  return (
    <div className="award-flex-con w-full flex h-full">
      <div
        style={{ height: "" }}
        className="award w-2/4 bg-slate-100 h-screen overflow-auto"
      >
        <div className="buttons flex justify-between items-center p-3">
         {/*  <Button variant="contained">Export to Excel</Button> */}
          <AwardDialog type="notapproved" />
         
        </div>
   
        {NewLocalGovts !== null && NewLocalGovts.map((localGovt, index)=>
        ( <AwardBatchAccordion type="notapproved" key={index} batch={`${localGovt.add_local_gov} Local Government`} lga={localGovt.add_local_gov} />))}
        
      </div>

      <div style={{ height: "" }} className="batch w-2/4  overflow-auto">
      <div className="buttons flex justify-between items-center p-3">
         
          <AwardDialog type="approved" />
          <AwardDialog type="sms" />
          <h1 className="text-center text-2xl font-extrabold">Awarded Applicants List</h1>
        </div>
        <div className="search p-3">
            
         
        </div>
        <div>
          {NewLocalGovts !== null && NewLocalGovts.map((localGovt, index)=>
        ( <AwardBatchAccordion searchText={searchText} type="approved" key={index} batch={`${localGovt.add_local_gov} Local Government`} lga={localGovt.add_local_gov} />))}
        
          
        </div>
      </div>   
    </div>
  );
}

export default Award;
