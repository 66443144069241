import React from "react";

function SPOList() {
  return (
    <div>
      <div className="flex justify-center items-center mt-2">
        <ul className="text-justify text-black list-disc">
          <li>Maybe a Civil servant on Grade level 13-14.</li>
          <li>May be a KADSIECOM Staff.</li>
          <li>
            Must have Bachelor / Master in any discipline or its equivalent.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SPOList;
