import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";

function AddPosition() {
  const [formData, setFormData] = useState({
    position: "",
  });

  const [newPosition, setNewPosition] = useState([]);

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleAdd = () => {
    if (formData.position.trim() !== "") {
      setNewPosition([...newPosition, formData.position]);
    }
  };

  const handleDelete = (index) => {
    const updatedPosition = newPosition.filter((_, i) => i !== index);
    setNewPosition(updatedPosition);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for empty fields
    const isEmptyField = Object.values(formData).every((value) => value === "");

    if (isEmptyField) {
      alert("Please fill out all fields");
    } else {
      console.log(formData);
    }
  };

  return (
    <div className="add-localGovt">
      <form onSubmit={handleSubmit}>
        <div className="input-con">
          <TextField
            label="Add Position"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.position}
            onChange={handleInputChange("position")}
            InputProps={{
              endAdornment: (
                <Button variant="contained" color="primary" onClick={handleAdd}>
                  Add
                </Button>
              ),
            }}
          />
        </div>
      </form>

      <div className="list-con">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Position
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Edit
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newPosition.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {item}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {/* <ViewDialog /> */}
                      <Button
                        className="Hover-"
                        elevation={10}
                        sx={{
                          whiteSpace: "nowrap",
                          backgroundColor: "rgb(1, 199, 1)",
                        }}
                        size="small"
                        variant="contained"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    <Button
                      className="Hover-"
                      // onClick={() => HanldeRemove(user.st_tokens)}
                      elevation={10}
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: "rgb(199, 1, 1)",
                      }}
                      size="small"
                      variant="contained"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AddPosition;
