import React, { useContext, useEffect, useState } from "react";
import { InputLabel, MenuItem, Select, TextField,Button } from "@mui/material";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";
import { FormControl, InputAdornment } from "@mui/material";
import Loader from "../../../Route/Loader";


function ApplicationStep() {



  const { Person,ReloadPersonData,setReloadPersonData,accesstoken} = useContext(ContextData);
  const [OpenLoader,setOpenLoader] = useState(false)
  const check = Person !== null && Person.applicant[0].submit_status

  const [formData, setFormData] = useState({
    specialNeeds:
      Person !== null &&
      (Person.applicant_application[0].person_with_spcl_need || ""),
    disabilityType:
      Person !== null &&
      (Person.applicant_application[0].type_of_disability || " "),
    positionApllied:
      Person !== null &&
      (Person.applicant_application[0].type_of_position_applied || " "),
    memberOfProBody:
      Person !== null &&
      (Person.applicant_application[0].member_of_any_professional_body || " "),
    nameOfProBody:
      Person !== null &&
      (Person.applicant_application[0].name_of_any_professional_body || " "),
    serveWherePosted:
      Person !== null &&
      (Person.applicant_application[0].will_server_at_posted_place || " "),
  });

 

  const handleupdate = () => {

    setOpenLoader(true)

    let ref_tokens =
    Person !== null && Person.applicant_wrk_experience[0].ref_tokens;
    const data = { ...formData, ref_tokens };

    axios
      .put(`${baseUrl}/auth/update_applctn/`, data, {
        withCredentials: true,
        headers: {
           'Accept': "application/json",
           'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        },
      })
      .then((res) => {
        setOpenLoader(false)
        toast.success(res.data);
        setReloadPersonData(!ReloadPersonData)
      })
      .catch((err) => {
        setOpenLoader(false)
        if (err) return console.log(err.message);
      });
  };

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };



  const needs = ["Yes", "No"];
  
  const position = [
    "Supervisory Presiding Officer (SPO)",
    "Presiding Officer (PO)",
    "Assistant Presiding Officer (APO)",
    "Poil Clerk(PC)",
  ];

  const contestedElection = ["Yes", "No"];
  const serveWherePosted = ["Yes", "No"];

  return (
    <div className="outer-con h-fit">

   {/*     <Loader
       OpenLoader={OpenLoader}
      /> */}
      <div className="form-con">
        <form >
          <InputLabel id="gender-input">Person with special needs</InputLabel>
          <FormControl sx={{display:"flex", flexDirection:"row"}} fullWidth>

          <Select
            label="Person with special needs"
            labelId="specialNeeds-input"
            id="specialNeeds-select"
            fullWidth
            value={formData.specialNeeds}
            onChange={handleInputChange("specialNeeds")}
          //  onDoubleClick={handleupdate}
            disabled={check === "Agreed" ? true : false}
          >
            <MenuItem value={needs[0]}>Yes</MenuItem>
            <MenuItem value={needs[1]}>No</MenuItem>
          </Select>
             <Button
                  variant="contained" 
                  color="primary"
                  onClick={handleupdate}
                  sx={{marginLeft:"1em"}}
                disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
          </FormControl>
          <p className="text-green-900 font-bold italic text-sm">
            *Click to save Details
          </p>
          {formData.specialNeeds === "Yes" && (
            <TextField
              label="Type Of Disability"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.disabilityType}
              onChange={handleInputChange("disabilityType")}
            //  onDoubleClick={handleupdate}
              disabled={check === "Agreed" ? true : false}
              InputProps={{
                endAdornment: (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleupdate}
                    disabled={check === "Agreed" ? true : false}
                  >
                    Save
                  </Button>
                )
              }}
            />
          )}
          
          <InputLabel id="position-input">Position Applied For</InputLabel>
          <FormControl sx={{display:"flex", flexDirection:"row"}} fullWidth>

          <Select
            label="Position Applied For"
            labelId="position"
            id="position-input"
            fullWidth
            value={formData.positionApllied}
            onChange={handleInputChange("positionApllied")}
          //  onDoubleClick={handleupdate}
            disabled={check === "Agreed" ? true : false}
          >
            {position.map((pos, index) => (
              <MenuItem key={index} value={pos}>
                {pos}
              </MenuItem>
            ))}
            </Select>
             <Button
                  variant="contained" 
                  color="primary"
                  onClick={handleupdate}
                  sx={{marginLeft:"1em"}}
                 disabled={check === "Agreed" ? true : false}>
                Save
            </Button>

          </FormControl>

          <p className="text-green-900 font-bold italic text-sm">
            *Click to save Details
          </p>

          <InputLabel id="gender-input">
            Will you serve wherever you are posted?
          </InputLabel>
          <FormControl sx={{display:"flex", flexDirection:"row"}} fullWidth>

          <Select
            label="Will you serve wherever you are posted?"
            labelId="serve-input"
            id="serve-select"
            fullWidth
            value={formData.serveWherePosted}
            onChange={handleInputChange("serveWherePosted")}
           // onDoubleClick={handleupdate}
            disabled={check === "Agreed" ? true : false}
          >
            <MenuItem value={serveWherePosted[0]}>Yes</MenuItem>
            <MenuItem value={serveWherePosted[1]}>No</MenuItem>
          </Select>
             <Button
                  variant="contained" 
                  color="primary"
                  onClick={handleupdate}
                  sx={{marginLeft:"1em"}}
                 disabled={check === "Agreed" ? true : false}>
                Save
            </Button>

          </FormControl>

          <p className="text-green-900 font-bold italic text-sm">*Click to save Details</p>
        </form>
      </div>
    </div>
  );
}

export default ApplicationStep;
