import React, { useState } from 'react';

const NunberCounter = (props) => {
  // Initializing state with a number starting at 0
  const [count, setCount] = useState();

  // Format the number with commas
  // const formattedNumber = count.toLocaleString();

  

  return (
  
      <h1 className='ml-1'> { props.countallApplicants.toLocaleString()}</h1>
    
   
  );
};

export default NunberCounter;