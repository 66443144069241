import React, { useState,useEffect ,useContext} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem
} from "@mui/material";
import { toast } from "react-toastify";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";
import validator from "validator";

function LimitApplicants() {


  const [formData, setFormData] = useState({
    localGovt: "",
    lgLimit: "",
    ward: "",
    wardLimit: "",
    unit: "",
    unitLimit: "",
  });
  const {accesstoken} = useContext(ContextData) 
  const [localGovtLimits, setLocalGovtLimits] = useState([]);
  const [Type,setType] = useState("")
  const [newLocalGovts, setnewLocalGovts] = useState(null);
  const [WardLists, setWardLists] = useState(null);
  const [UnitLists, setUnitLists] = useState(null);
  const [FoundedWards,setFoundedWards] = useState(null)
  const [FoundedUnits,setFoundedUnits] = useState(null)
  const [Reload,setReload] = useState(true)

  // Hooks for inputs to send to server
  const [Local_gov_limit,setLocal_gov_limit] = useState("")
  const [Local_gov_limit_token,setLocal_gov_limit_token] = useState("")
  // Ward
  const [Ward_limit,setWard_limit] = useState("")
  const [ward_token_limit,setward_token_limit] = useState("")
  // Unit
  const [Unit_limit,setUnit_limit] = useState("")
  const [Unit_token_limit,setUnit_token_limit] = useState("")



  useEffect(() => {
    async function FetchData() {
    //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setnewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();

  },[Reload]); 


  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_wards`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setWardLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_unit`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setUnitLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  useEffect(()=>{

    const finded = WardLists !== null && WardLists.filter( item =>  item.add_local_gov ===  formData.localGovt  )
  

     if(finded){
      setFormData({...formData,ward: "",unit:""})
      setFoundedWards(finded)
     }else{ 
      setFoundedWards(null)
     }
     

},[formData.localGovt])


useEffect(()=>{

  const finded = UnitLists !== null && UnitLists.filter( item =>  item.add_ward_gov ===  formData.ward  )

   

   if(finded){
    setFoundedUnits(finded)
   }else{
    setFoundedUnits(null)
   }
   

},[formData.ward])

// filter local government 

useEffect(()=>{

        
     const finded = newLocalGovts !== null && newLocalGovts.find( item =>  item.tokens ===  formData.localGovt.slice(formData.localGovt.indexOf("=")+1) )

      if(finded){

        setLocal_gov_limit(finded.limit_local_Gov)
        setLocal_gov_limit_token(finded.tokens)
      
      
       }else{

        setLocal_gov_limit("") 
        setLocal_gov_limit_token("")
       }


 
},[formData.localGovt])


// Filter Ward

useEffect(()=>{    
  
  const finded = WardLists !== null && WardLists.find( item =>  item.add_ward_gov ===  formData.ward.slice(0,formData.ward.indexOf("=")) )
  // console.log(finded)
   if(finded){
      
    setWard_limit(finded.limit_ward)
    setward_token_limit(finded.tokens)
     
   
    }else{

      setWard_limit("") 
      setward_token_limit("")
    }



},[formData.ward])


/* Filter Unit */

useEffect(()=>{    
  
  const finded = UnitLists !== null && UnitLists.find( item =>  item.tokens ===  formData.unit.slice(formData.unit.indexOf("=") + 1) )
  // console.log(finded)
   if(finded){
      
    setUnit_limit(finded.limit_unit)
    setUnit_token_limit(finded.tokens)
    
    
    }else{

      setUnit_limit("") 
      setUnit_token_limit("")
    }



},[formData.unit])

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

//  handleLocalLimitUpdate
  const handleLocalLimitUpdate = () => {
  
    
   if(validator.isEmpty(Local_gov_limit.toString()) || validator.isEmpty(Local_gov_limit_token)) return toast.warn("Limit input can't be empty")
   
    const data = {Local_gov_limit_token,Local_gov_limit}

    axios.put(`${baseUrl}/auth/limit_local_gov`,data,{
      withCredentials: true,
      headers: {
        Accept: "application/json",
         'authorization': `Bearer ${accesstoken}`
      },
    })
    .then((res) => {
   
          toast.success(res.data)
          setReload(!Reload)
         
    })
    .catch((err) => {
      if (err) return console.log(err.message);
    });





  };

  // handleWardLimitUpdate
  const handleWardLimitUpdate = () => {  
      
    if(validator.isEmpty(Ward_limit.toString()) || validator.isEmpty(ward_token_limit)) return toast.warn("Limit input can't be empty")
    
     const data = {Ward_limit,ward_token_limit}
 
     axios.put(`${baseUrl}/auth/limit_ward`,data,{
       withCredentials: true,
       headers: {
         Accept: "application/json",
          'authorization': `Bearer ${accesstoken}`
       },
     })
     .then((res) => {
    
           toast.success(res.data)
          setReload(!Reload)
          
     })
     .catch((err) => {
       if (err) return console.log(err.message);
     });
 

   };
 

   // handleUnitLimitUpdate 
  const handleUnitLimitUpdate = ()=>{


      if(validator.isEmpty(Unit_limit.toString()) || validator.isEmpty(Unit_token_limit)) return toast.warn("Limit input can't be empty")
    
        const data = {Unit_limit,Unit_token_limit}
    
        axios.put(`${baseUrl}/auth/limit_unit`,data,{
          withCredentials: true,
          headers: {
            Accept: "application/json",
             'authorization': `Bearer ${accesstoken}`
          },
        })
        .then((res) => {
       
              toast.success(res.data)
             setReload(!Reload)
             
        })
        .catch((err) => {
          if (err) return console.log(err.message);
        });



    }



  return (
    <div className="add-localGovt">
       <div>
         <h2 className="text-center">Regulation Type</h2>
       <TextField
            select
            label="Regulation Type"
            variant="outlined"
            fullWidth
            margin="normal"
            value={Type}
            onChange={(e)=> setType(e.target.value)}
          >
             <MenuItem  value="lga">Local Government</MenuItem>
             <MenuItem  value="ward">Ward</MenuItem>
             <MenuItem  value="unit">Unit</MenuItem>
          </TextField>

       </div>
      <div>

         {
            Type === "lga" && 
        <div className="input-con">
    
         <TextField
            select
            label="Select Local Government"
            variant="outlined"
            fullWidth
            name="localGovt"
            margin="normal"
            value={formData.localGovt}
            onChange={handleInputChange("localGovt")}
          >
            { 
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem  key={index} value={list.add_local_gov+"="+list.tokens}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>

        </div>
         }

         {
           Type === "ward" && 
        <div className="input-con">
           <TextField
            select
            label="Select Local Government"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.localGovt}
            onChange={handleInputChange("localGovt")}
          >
            { 
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>

        
          
          <TextField
            select
            label="Add Ward"
            variant="outlined"
            fullWidth
            margin="normal"
            name="ward"
            value={formData.ward}
            onChange={handleInputChange("ward")}
          >
             {
             FoundedWards !== null && FoundedWards.map((list,index)=>(

              <MenuItem key={index} value={list.add_ward_gov+"="+list.tokens}>{list.add_ward_gov}</MenuItem>

            ))
          }
          </TextField>


        </div>
         }


         {
           Type === "unit" && 
           <div className="input-con">
           <TextField
            select
            label="Select Local Government"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.localGovt}
            onChange={handleInputChange("localGovt")}
          >
            { 
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>

        
          
          <TextField
            select
            label="Wards"
            variant="outlined"
            fullWidth
            margin="normal"
            name="ward"
            value={formData.ward}
            onChange={handleInputChange("ward")}
          >
             {
             FoundedWards !== null && FoundedWards.map((list,index)=>(

              <MenuItem key={index} value={list.add_ward_gov}>{list.add_ward_gov}</MenuItem>

            ))
          }
          </TextField>


          <TextField
            select
            label="Units"
            variant="outlined"
            fullWidth
            margin="normal"
            name="unit"
            value={formData.unit}
            onChange={handleInputChange("unit")}
          >
             {
             FoundedUnits !== null && FoundedUnits.map((list,index)=>(

              <MenuItem key={index} value={list.add_unit_gov+"="+list.tokens}>{list.add_unit_gov}</MenuItem>

            ))
          }
          </TextField>


        </div>
         }

    
      </div>
      <div className="list-con">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Local Government
                </TableCell>

                {
                 ( Type === "ward" || Type === "unit") &&
                  <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                   Ward
                </TableCell>
                }


                      {
                  Type === "unit" &&
                  <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                   Unit
                </TableCell>
                }

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Limit
                </TableCell>

              
              
              </TableRow>
            </TableHead>


             {/* Local Government  */}
                {
                  Type === "lga"  &&
            <TableBody>
           
                <TableRow>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    1
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {formData.localGovt.slice(0,formData.localGovt.indexOf("="))} 
                  </TableCell> 
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>     
                      <TextField
                        label="Limit"
                        variant="outlined" 
                        fullWidth
                        margin="normal"
                        value={Local_gov_limit}
                        type="text"
                        onChange={(e)=> setLocal_gov_limit(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <Button onClick={handleLocalLimitUpdate} variant="contained" color="primary">
                              Set Limit
                            </Button>
                          ),
                        }}
                      />
                  </TableCell>
                 
                </TableRow>
         
            </TableBody>
                }
              
              {
                  Type === "ward"  &&
            <TableBody>
           
                <TableRow>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    1
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  {formData.localGovt} 
                  </TableCell> 

                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {formData.ward === "" ? "Select Ward" :   formData.ward.slice(0,formData.ward.indexOf("="))} 
                  </TableCell>  
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>     
                      <TextField
                        label="Limit"
                        variant="outlined" 
                        fullWidth
                        margin="normal"
                        value={Ward_limit}
                        type="text"
                        onChange={(e)=> setWard_limit(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <Button onClick={handleWardLimitUpdate} variant="contained" color="primary">
                              Set Limit
                            </Button>
                          ),
                        }}
                      />
                  </TableCell>
                 
                </TableRow>
         
            </TableBody>
                }

{
                  Type === "unit"  &&
            <TableBody>
           
                <TableRow>
                <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    1
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  {formData.localGovt} 
                  </TableCell> 

                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {formData.ward === "" ? "Select Ward" :   formData.ward} 
                  </TableCell>  
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {formData.unit === "" ? "Select Unit" :   formData.unit.slice(0,formData.unit.indexOf("="))} 
                  </TableCell>  
                 

                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>     
                      <TextField
                        label="Limit"
                        variant="outlined" 
                        fullWidth
                        margin="normal"
                        value={Unit_limit}
                        type="text"
                        onChange={(e)=> setUnit_limit(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <Button onClick={handleUnitLimitUpdate} variant="contained" color="primary">
                              Set Limit
                            </Button>
                          ),
                        }}
                      />
                  </TableCell>
                 
                </TableRow>
         
            </TableBody>
                }


          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default LimitApplicants;
