import React from 'react'

function Footer() {

  const d = new Date();
  let Year = d.getFullYear();



  return (
    <div className="footer">
       <div className=" flex justify-center items-cente flex-col ">
        <p className="font-extrabold">
          All right reserved &copy; {Year}: Kaduna State Government
        </p>
        <p className="text-center">
          <a target="_blank" href="https://mmt-ng.com/">
            Developed By MastermindTech
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer