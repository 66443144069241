import React, { useState } from "react";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from '@mui/icons-material/Close';


function Nav() {
  const Navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => {
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <div className="nav">
      <div className="nav-logo">
        <img src="/assets/siecom.png" alt="logo" className="w-14 rounded-full" />
        <h2>KAD-SIECOM</h2>
      </div>
      <div className="nav-links">
        <ul>
          <li onClick={() => Navigate("/contact")}> Contact Us </li>
          <li onClick={() => Navigate("/portal")}> Application </li>
        </ul>
        {isOpen ? <CloseIcon onClick={closeMenu} className="close-icon" /> :  <MenuIcon onClick={openMenu} className="menu-icon" />}
      </div>
      <div className={ isOpen ? "opened" : "mobile-nav"}>
        
        <div className="mobile-nav-links">
          <ul>
          <li onClick={() => Navigate("/contact")}> Contact Us </li>
          <li onClick={() => Navigate("/portal")}> Application </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Nav;
