import React from "react";

function SectionOne() {
  return (
    <div className="section-one">
      <div className="heading">
        <h1>
          ADVERTISEMENT FOR RECRUITMENT OF ADHOC STAFF 
        </h1>
      </div>
      <div className="sub-heading">
        {/* <p>amet consectetur adipisicing elit:</p> */}
      </div>
      <div className="content">
        <p>
          {" "}
          <span>Kaduna State
          Independent Electoral Commission - </span>  in preparations for the forth-coming
          Local Government Councils Election hereby invites applications from
          suitably qualified candidates to fill the following positions
        </p>
      </div>
    </div>
  );
}

export default SectionOne;
