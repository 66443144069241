import React from "react";

function PCList() {
  return (
    <div>
      <div className="flex justify-center items-center mt-2">
        <ul className="text-justify text-black list-disc">
          <li>
            May be State civil servant on Grade Level 06-08 or its equivalent.
          </li>
          <li>ND/NCE.</li>
          <li>
            Previous knowledge of Election operations will be an added
            advantage.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default PCList;
