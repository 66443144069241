import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { MenuItem, TextField,Button } from "@mui/material";
import axios from "axios";
import baseUrl from "../../../Route/BaseURL";
import { ContextData } from "../../context/Context";
import validator from "validator";
import { toast } from "react-toastify";
import Loader from "../../../Route/Loader";


export default function AwardOptions(props) {


  
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState(false);
  const {accesstoken,ReloadAwardList,setReloadReloadAwardList} = React.useContext(ContextData)
  var smstext = localStorage.getItem('smstext');
  const [OpenLoader,setOpenLoader] = useState(false)
  const [NewLocalGovts,setNewLocalGovts] = useState(null)
 
  const position = [
    "All",
    "Supervisory Presiding Officer (SPO)",
    "Presiding Officer (PO)",
    "Assistant Presiding Officer (APO)",
    "Poil Clerk(PC)",
  ];

  const [formData, setFormData] = useState({
    lga: "",
    position:"",
    message: smstext || ""
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  React.useEffect(()=>{


    localStorage.setItem('smstext', formData.message);
          

  },[formData.message])
 
  React.useEffect(() => {

    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }

    FetchData();
    
  },[]); 


  const handleAward = () => {
    

    if(validator.isEmpty(formData.lga)){
         toast.warn("No Empty is allow ")
         return
    }
  


    
    axios.put(`${baseUrl}/auth/award`,formData,{
      withCredentials: true,
      headers: {
        Accept: "application/json",
        'authorization': `Bearer ${accesstoken}`
      },
    })
    .then((res) => {

        if(res.data.status){

          toast.success(res.data.text); 

        }else{
          toast.warn(res.data.text);
        }
      setReloadReloadAwardList(!ReloadAwardList)

    })
    .catch((err) => {
      if (err) return console.log(err.message);
    });






  };

  
  const handleExport = () => {


    
    if(validator.isEmpty(formData.lga)){
         toast.warn("No Empty is allow ")
         return
    }
  
       
    if(validator.isEmpty(formData.position)){
      toast.warn("No Empty is allow ")
      return
 }
    


    
    axios.get(`${baseUrl}/pub/get_contact/${formData.lga}/${formData.position}`, {
      withCredentials: true,
      responseType: 'blob', // Important to get the response as a blob
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    })
    .then((response) => {  
      // Create a new Blob object using the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
    
      // Create a link element
      const link = document.createElement('a');
      
      // Create a URL for the blob object
      const url = window.URL.createObjectURL(blob);
      link.href = url;
    
      // Set the download attribute to define the file name
      link.setAttribute('download', `${formData.lga}${formData.position}-List_${Date.now()}.xlsx`);
      
      // Append the link to the document body
      document.body.appendChild(link);
    
      // Programmatically trigger a click to download the file
      link.click();
    
      // Remove the link after the download
      document.body.removeChild(link);
    
      // Optionally, revoke the blob URL to free up memory
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.log('Error downloading the file:', err.message);
    });
    





  };


const handleBulkSms = () =>{

  if(validator.isEmpty(formData.lga)){
    toast.warn("No Empty is allow ")
    return
}

if(validator.isEmpty(formData.position)){
  toast.warn("No Empty is allow ")
  return
}

if(validator.isEmpty(formData.message)){
  toast.warn("No Empty is allow ")
  
  return
}

setOpenLoader(true) 
axios.put(`${baseUrl}/auth/send_bulksms`,formData,{
 withCredentials: true,
 headers: {
   Accept: "application/json",
   'authorization': `Bearer ${accesstoken}`
 },
})
.then((res) => {

     toast.success(res.data);
     setOpenLoader(false) 

})
.catch((err) => {
  setOpenLoader(false) 
 if (err) return console.log(err.message);
});




}

  return (
    <div>
           <Loader
             OpenLoader={OpenLoader}
            />
        <TextField
          select
          label="Select Local Government"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.lga}
          onChange={handleInputChange("lga")}
        >
               {
            NewLocalGovts !== null && NewLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
        </TextField>



        <TextField
          select
          label="Select Position"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.position}
          onChange={handleInputChange("position")}
        >
               {
            position !== null && position.map((list,index)=>(

              <MenuItem key={index} value={list}>{list}</MenuItem>

            ))
          }
        </TextField>
    
           {
                props.type === "sms" &&  
             <TextField
              label="Message"
              name="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              margin="normal"
              value={formData.message}
              onChange={handleInputChange("message")}
            />
           }

        {
           props.type === "approved" &&   <Button onClick={handleExport} variant="contained">Export</Button>  
        }

       
           {
           props.type === "notapproved" &&     <Button onClick={handleAward} variant="contained">Award</Button>  
        }

       
        {
           props.type === "sms" &&   <Button onClick={handleBulkSms} variant="contained">Send Bulk</Button>  
        }




    </div>
  );
}
 