import React from "react";

function Hero() {
  return (
    <div className="hero">
      <div className="left">
        <h2 className="main-heading">
          Kaduna State Independent Electoral Commission
        </h2>
        <p className="sub-heading">
          Integrity, Accountability & Inclusiveness in Election
        </p>
      </div>
      {/* <div className='right'>
        &nbsp;
      </div> */}
    </div>
  );
}

export default Hero;
