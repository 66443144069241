import React from "react";

function BioData(props) {
  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Work Experience </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        
        <ul>
           {/*  <li><b>Present Employer:</b> <span> {props.data.}</span></li>
            <li><b>Present Rank:</b> <span></span></li> */}
            <li><b>Years Of Working Experience:</b> <span> {props.data.years_of_wrk} Years </span></li>

        </ul>

      </div>

      
    </div>
  );
}

export default BioData;
