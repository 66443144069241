import React, { useContext } from 'react'
import { Outlet,Navigate} from 'react-router-dom'
import { ContextData } from '../../components/context/Context'



const useAuth = () =>{

  const {accesstoken,Checker} = useContext(ContextData)
  let usertype = ["applicant","Administrator","manage","Electrol Officer","Selector","Account Helper"]
    let result
       if(accesstoken !== null){

         if(Checker === usertype[3]){
    
            result = true
         }
       }else{
         result = false
       }


  return result
}



function ElectoralOfficerPrivateRoute() {

  const IsAuth = useAuth()
 // useAuth()
  return <>{ IsAuth ? <Outlet /> : <Navigate to="/login" /> }</>
}

export default ElectoralOfficerPrivateRoute




