import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { ContextData } from '../../context/Context';
import baseUrl from '../../../Route/BaseURL';
import axios from 'axios';
import { Person } from '@mui/icons-material';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width: '80%'
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1), 
      width: '100%',
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));




const UpdatePassword = () => {


  const {ApplicantTokens,accesstoken,setPerson,Person} = useContext(ContextData)
  const classes = useStyles();


 
   // Get configroute for usertype to access the routes
 useEffect(()=>{


  async function FetchData(){
             
      try { 
          
        const response = await axios.get(`${baseUrl}/auth/gets_single_admin_user/${ApplicantTokens}`,{
          withCredentials: true,
           headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${accesstoken}`
          }
          } 
          ) 
     
          setPerson(response.data[0])
      
     } catch (err) {
       if(err) console.log(err.message)
     }  
  
    }
    
    FetchData() 

 },[ApplicantTokens])
 

 const [formData, setFormData] = useState({
  email: Person !== null && Person.email || '',
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value.trim() }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if any field is empty
    if (Object.values(formData).some((value) => value === '')) {
      toast.warn('Please fill in all fields');
      return;
    }

    if(formData.newPassword !== formData.repeatPassword){
        toast.warn("Password Not Match")
        return
    }


     const data = {...formData,token:ApplicantTokens}
    axios.post(`${baseUrl}/auth/update_passward`,data,{
      withCredentials: true,
      headers: {
        Accept: "application/json",
         'authorization': `Bearer ${accesstoken}`
      },
    })
    .then((res) => {
   
          
          if(res.data.status){
            toast.success(res.data.statusText)
          }else{
            toast.warn(res.data.statusText)
          }
        
    })
    .catch((err) => {
      if (err) return console.log(err.message);
    });



  };


  return (
    <div  className=" flex justify-center items-center" style={{width:"100%",height:"100%"}}>
       
       
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Change Password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled
              />
              <TextField
                label="Old Password"
                type="password"
                name="oldPassword"
                value={formData.oldPassword}
                onChange={handleChange}
                required
              />
              <TextField
                label="New Password"
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                required
              />
              <TextField
                label="Repeat New Password"
                type="password"
                name="repeatPassword"
                value={formData.repeatPassword}
                onChange={handleChange}
                required
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.button}
              >
                Submit
              </Button>
            </form>
          </Paper>
        
    
    </div>
  );
};

export default UpdatePassword;


// get_data_adminuser