import React, { useContext, useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button
} from "@mui/material";
import NigerianStates from "./NigerianStates";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Route/Loader";
import { ContextData } from "../../context/Context";

function DemographyStep() {


  const [open, setOpen] = React.useState(false);
  const {Person,accesstoken} = useContext(ContextData)
  const [OpenLoader,setOpenLoader] = useState(false)
  const check = Person !== null && Person.applicant[0].submit_status



  const [formData, setFormData] = useState({
    state:
      (Person !== null &&
        Person.applicant_demography[0].applcnt_state_of_origin) ||
      " ",
    lgaOfResidence:
      (Person !== null && Person.applicant_demography[0].local_gov) || " ",
    wardOfResidence:
      (Person !== null && Person.applicant_demography[0].applcnt_ward) || " ",
    contactAddress:
      (Person !== null && Person.applicant_demography[0].applcnt_address) ||
      " ",
  });



  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
 
    
  };

  const  handleupdate = ()=>{

     
    setOpenLoader(true)


    let ref_tokens = Person !== null && Person.applicant_demography[0].ref_tokens
        
    axios.put(`${baseUrl}/auth/update_address/${formData.contactAddress}/${ref_tokens}`, {
        withCredentials: true,
        headers: {
           Accept: "application/json",
           authorization: accesstoken ? `Bearer ${accesstoken !== null && accesstoken}` : '',
        },
      }).then(res=>{ 
        setOpenLoader(false)     
        toast.success(res.data)
        

       })
      .catch((err) => {
        setOpenLoader(false)
        if (err) return console.log(err.message);
      });
  };


  return (
    <div className="outer-con h-fit">

      <Loader
       OpenLoader={OpenLoader}
      /> 
      <div className="form-con">
         
        <form>
          <InputLabel id="state-label">State Of Origin</InputLabel>
          <Select
            label="State Of Origin"
            labelId="state-label"
            id="state"
            fullWidth
            margin="normal"
            value={formData.state}
            onChange={handleInputChange("state")}
            disabled
          >
            {NigerianStates.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Applicants LGA Of Residence"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.lgaOfResidence}
            onChange={handleInputChange("lgaOfResidence")}
            disabled
          />
          <TextField
            label="Applicants Ward Of Residence"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.wardOfResidence}
            onChange={handleInputChange("wardOfResidence")}
            disabled
          />
          <TextField
            label="Contact Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.contactAddress}
            onChange={handleInputChange("contactAddress")}
           // onDoubleClick={handleupdate}
            disabled={check === "Agreed" ? true : false}
             InputProps={{
              endAdornment: (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleupdate}
                  disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
              )
            }}
          />
        {/*   <p className="text-green-900 font-bold italic text-sm">
            *Double Click to save Details
          </p> */}
        </form>
      </div>
    </div>
  );
}

export default DemographyStep;
