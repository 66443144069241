import React, { useContext, useEffect, useMemo, useState } from "react";
import { ContextData } from "../../context/Context";
import axios from "axios";
import baseUrl from "../../../Route/BaseURL";
import BadgePOp from "../../Pages/BadgePOp";
import { toast } from "react-toastify";
import Loader from "../../../Route/Loader";

function AwardBatchAccordion(props) {
    const [ApplicantData, setApplicantData] = useState(null);
    const { accesstoken, ReloadAwardList } = useContext(ContextData);
    const [CouuntAward, setCouuntAward] = useState(null);
    const [OpenLoader,setOpenLoader] = useState(false)

    useEffect(() => {
        async function FetchData() {
            const url =
                props.type === "notapproved"
                    ? `${baseUrl}/auth/get_applicants_by_local/${props.lga}`
                    : `${baseUrl}/auth/get_applicants_by_local_approve/${props.lga}`;
            try {
                const response = await axios.get(url, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        authorization: `Bearer ${accesstoken !== null && accesstoken}`,
                    },
                });
                setApplicantData(response.data);
                setCouuntAward(response.data.length);
            } catch (err) {
                console.log(err.message);
            }
        }
        FetchData();
    }, [ReloadAwardList, props.lga, props.type, accesstoken]);

    // Function to find duplicates
    const findDuplicates = (data) => {
        const duplicates = {};
        data.forEach((item) => {
            const key = `${item.fullname}-${item.r_ID}`;
            if (duplicates[key]) {
                duplicates[key] = duplicates[key] + 1;
            } else {
                duplicates[key] = 1;
            }
        });
        return duplicates;
    };

    // Find duplicate rows
    const duplicates = ApplicantData ? findDuplicates(ApplicantData) : {};

    const HandleRemove = (r_ID, mobile, user_token, awardlist_id) => {

        const data = { r_ID, mobile, user_token, awardlist_id };

        axios
            .put(`${baseUrl}/auth/removefromAwardlist_award`, data, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    authorization: `Bearer ${accesstoken}`,
                },
            })
            .then((res) => {
                toast.success(res.data);
            })
            .catch((err) => {
                if (err) console.log(err.message);
            });
    };


    const HandleMessage = (r_ID, mobile, user_token,awardlist_id) => {

        setOpenLoader(true)
         let phone 

       

         if (mobile.startsWith("+234")) {
            phone = mobile.slice(4);  // Remove the +234 prefix
        } else if (mobile.startsWith("234")) {
            phone = mobile.slice(3);  // Remove the 234 prefix
        } else {
            phone = mobile;  // Use the mobile number as is
        }

        // Ensure the phone is 11 digits long, starting with '0'
        if (phone.length === 11 && phone.startsWith("0")) {
            // Remove the first '0' to get 10 digits
            phone = phone.slice(1);
        } else {
            toast.warn("Invalid Number, move to next applicant")
            setOpenLoader(false)
            return
        }


        
        
        const data = { r_ID,phone,user_token,awardlist_id };
          

        axios.put(`${baseUrl}/auth/send_sms`,data,{
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    authorization: `Bearer ${accesstoken}`,
                },
            })
            .then((res) => {

                toast.success(res.data)
                setOpenLoader(false)

            })
            .catch((err) => {
                if (err) console.log(err.message);
                setOpenLoader(false)
            });

    };



    return (
        <div className="p-2">

            <Loader
             OpenLoader={OpenLoader}
            />

            <div className="accordion">
                <div className="accordion-summary">


                    <p className="font-bold flex">
                        {props.batch}{" "}
                        <span className="pl-3">
                            {CouuntAward !== null ? <BadgePOp value={CouuntAward} /> : <BadgePOp value={CouuntAward} />}
                        </span>
                    </p>


                </div>

                <div className="accordion-details">
                    <div className="bg-slate-50 p-4 h-96 rounded-lg overflow-auto">
                        <p className="m-3">
                            <span className="p-2 border-r-2 border-red-900 whitespace-nowrap">
                                <b>Total SPO:</b> {ApplicantData ? ApplicantData.filter((list) => list.post === "Supervisory Presiding Officer (SPO)").length : 0}
                            </span>
                            <span className="p-2 border-r-2 border-red-900 whitespace-nowrap">
                                <b>Total PO:</b> {ApplicantData ? ApplicantData.filter((list) => list.post === "Presiding Officer (PO)").length : 0}
                            </span>
                            <span className="p-2 border-r-2 border-red-900 whitespace-nowrap">
                                <b>Total APO:</b> {ApplicantData ? ApplicantData.filter((list) => list.post === "Assistant Presiding Officer (APO)").length : 0}
                            </span>
                            <span className="p-2 border-r-2 border-red-900 whitespace-nowrap">
                                <b>Total PC:</b> {ApplicantData ? ApplicantData.filter((list) => list.post === "Poil Clerk(PC)").length : 0}
                            </span>
                        </p>

                        {/* Native HTML Table */}
                        <table className="table-auto w-full text-left">
                            <thead className="bg-gray-800 text-white">
                                <tr>
                                    <th className="px-4 py-2">No.</th>
                                    <th className="px-4 py-2">Fullname</th>
                                    <th className="px-4 py-2">Position</th>
                                    <th className="px-4 py-2">Ward</th>
                                    <th className="px-4 py-2">ID</th>
                                    {props.type === "approved" && <th className="px-4 py-2 text-nowrap">Send SMS</th>}
                                  {/*   {props.type === "approved" && <th className="px-4 py-2">Remove</th>} */}
                                    {props.type === "notapproved" && <th className="px-4 py-2">Remove</th>}
                                </tr>
                            </thead>
                          
                            <tbody> 
                                {ApplicantData !== null &&
                                    ApplicantData.map((list, index) => {
                                        const key = `${list.fullname}-${list.r_ID}`;
                                        const isDuplicate = duplicates[key] > 1;
                                        return (
                                            <tr title={`Selected by : ${list.selected_by}`} key={index} className={`${isDuplicate ? 'bg-red-300' : 'bg-white'} border-b`}>
                                                <td className="px-4 py-2">{index + 1}</td>
                                                <td className="px-4 py-2 whitespace-nowrap">{list.fullname}</td>
                                                <td className="px-4 py-2">{list.post.slice(list.post.indexOf("("))}</td>
                                                <td className="px-4 py-2">{list.ward}</td>
                                                <td className="px-4 py-2">{list.r_ID}</td>
                                                {props.type === "notapproved" && (    
                                                    <td className="px-4 py-2">
                                                        <button
                                                            onClick={() =>
                                                                HandleRemove(
                                                                    list.r_ID,
                                                                    list.mobile,
                                                                    list.ref_tokens,
                                                                    list.awardlist_id
                                                                )
                                                            }
                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                )}

                                                    {props.type === "approved" && (    
                                                    <td className="px-4 py-2">
                                                    <button
                                                    onClick={() =>
                                                    HandleMessage(
                                                    list.r_ID,
                                                    list.mobile,
                                                    list.ref_tokens,
                                                    list.awardlist_id
                                                    )
                                                    }

                                                   disabled={list.post.slice(list.post.indexOf("(")) !== "(APO)" ? true : false}
                                                    className="sms-btn bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-nowrap"
                                                    >
                                                    Send SMS
                                                    {/* {list.mobile.slice(list.mobile.indexOf("0") + 1)} */}
                                                    </button> 
                                                    </td>
                                                    )} 
                                                  
                                            {/*       {props.type === "approved" && (    
                                                    <td className="px-4 py-2">
                                                        <button
                                                            onClick={() =>
                                                                HandleRemove(
                                                                    list.r_ID,
                                                                    list.mobile,
                                                                    list.ref_tokens,
                                                                    list.awardlist_id
                                                                )
                                                            }
                                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                )} */}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AwardBatchAccordion;