import React, { useContext, useEffect, useState } from "react";
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button
} from "@mui/material";

import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";


function BioDataStep() {

  const {Person,ReloadPersonData,setReloadPersonData,accesstoken} = useContext(ContextData)
 


  
  const [formData, setFormData] = useState({
    firstName: Person !== null && Person.applicant_bio[0].fname || "loading.. click next button",
    lastName: Person !== null && Person.applicant_bio[0].lname || "loading.. click next button",
    dob: Person !== null && Person.applicant_bio[0].d_of_birth || "loading.. click next button",
    gender: Person !== null && Person.applicant_bio[0].gender || "loading.. click next button",
    mobile: Person !== null && Person.applicant_bio[0].mobile || "loading.. click next button",
    email: Person !== null && Person.applicant_bio[0].email || "loading.. click next button",
    
  }); 
  const check = Person !== null && Person.applicant[0].submit_status
  const gender = ["male", "female"];


  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const [file, setFile] = useState(null);
 
  const [OpenLoader,setOpenLoader] = useState(false)
 

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

    let ref_tokens = Person !== null && Person.applicant_edu[0].ref_tokens;


  const handleUpload = async () => {


     const ref_tokens =  Person !== null && Person.applicant_edu[0].ref_tokens

    if (!file) {
      toast.warn('Please select a file first!');
      return;
    }

        // Check file size (2MB = 2 * 1024 * 1024 bytes)
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
          toast.warn('File size exceeds 2MB. Please choose a smaller file.');
          return;
        }
    

    const formData = new FormData();
    formData.append('file', file);
    formData.append('ref_tokens', ref_tokens);

    try {
      const response = await axios.put(`${baseUrl}/auth/upload_pic_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
           'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        },
      });
      toast.success('Uploaded Successfully:', response.data);
      setReloadPersonData(!ReloadPersonData)
    } catch (error) {
      toast.warn('Error uploading file:', error.message);
    }
  };




  return (
    <div className="outer-con h-fit">
      <div className="form-con">
        <form>
        <div>
            <label>Upload Photo</label>
          <TextField
            id="pic"
            type="file"
            fullWidth
            variant="outlined"
            onChange={handleFileChange}
            inputProps={{
              accept: "png,gpj,gpeg",
              multiple: true // if you want to allow multiple file selection
            }}
           disabled={check === "Agreed" ? true : false}
           
          />
           {
             Person !== null && <>
              { Person.applicant_bio[0].picture ?
              
              <span style={{color:"green",fontWeight:"800",marginTop:"1em"}}>Photo Uploaded</span>
                 :

                 <span style={{color:"red",fontWeight:"800",marginTop:"1em"}}>You haven't Upload your Passport Photo</span>
              }
              
               
             </>
           }
          

          </div>
          <Button sx={{marginTop:"1em"}} variant="contained" onClick={handleUpload}>
        Upload
        </Button>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.firstName}
            onChange={handleInputChange("firstName")}
            disabled
          />
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.lastName}
            onChange={handleInputChange("lastName")}
            disabled
          />
          <TextField
            label="Date of birth"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.dob}
            onChange={handleInputChange("dob")}
            disabled
          />
          <InputLabel id="gender-input">Gender</InputLabel>
          <Select
            label="Gender"
            labelId="gender-input"
            id="gender-select"
            fullWidth
            value={formData.gender}
            onChange={handleInputChange("gender")}
            disabled
          >
            <MenuItem value={gender[0]}>Male</MenuItem>
            <MenuItem value={gender[1]}>Female</MenuItem>
          </Select>
    
          <TextField
            label="Mobile Number(s)"
            type="tel"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.mobile}
            onChange={handleInputChange("mobile")}
            disabled
          />
          <TextField
            label="Email Address"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.email}
            onChange={handleInputChange("email")}
            disabled
          />
          
        </form>
      </div>
    </div>
  );
}

export default BioDataStep;
