import React, { useState,useContext, useEffect } from "react";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { toast } from "react-toastify";
import {  Button } from '@mui/material';
import Loader from "../../../Route/Loader";
import { ContextData } from "../../context/Context";
import { FormControl, InputAdornment } from "@mui/material";


function EducationStep() {

  const {Person,ReloadPersonData,setReloadPersonData,accesstoken} = useContext(ContextData)
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    highestEdu: Person !== null && Person.applicant_edu[0].highst_edu || "",
  });
  const [OpenLoader,setOpenLoader] = useState(false)
  const check = Person !== null && Person.applicant[0].submit_status

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

    let ref_tokens = Person !== null && Person.applicant_edu[0].ref_tokens;


  const handleUpload = async () => {

    

    const ref_tokens =  Person !== null && Person.applicant_edu[0].ref_tokens

    if (!file) {
      toast.warn('Please select a file first!');
      return;
    }

        // Check file size (2MB = 2 * 1024 * 1024 bytes)
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
          toast.warn('File size exceeds 2MB. Please choose a smaller file.');
          return;
        }
    

    const formData = new FormData();
    formData.append('file', file);
    formData.append('ref_tokens', ref_tokens);
    setOpenLoader(true)
    try {
      const response = await axios.put(`${baseUrl}/auth/update_edu_file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
           'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        },
      });

      setOpenLoader(false)   
      toast.success('File uploaded successfully:', response.data);
      setReloadPersonData(!ReloadPersonData)

    } catch (error) {
      setOpenLoader(false)   
      toast.warn('Error uploading file:', error.message);
    }
  };

  const HandleUpdate = ()=>{

    setOpenLoader(true)
    let ref_tokens = Person !== null && Person.applicant_edu[0].ref_tokens
      
   axios.put(`${baseUrl}/auth/update_edu/${formData.highestEdu}/${ref_tokens}`, {
        withCredentials: true,
        headers: {
          'Accept': "application/json",
          'authorization': `Bearer ${accesstoken !== null && accesstoken}`
        },
      }) .then(res=>{ 
        setOpenLoader(false)     
        toast.success(res.data)
        setReloadPersonData(!ReloadPersonData)
       }) 
      .catch((err) => {
        setOpenLoader(false)
        if (err) return console.log(err.message);
      })
 
  }

  const edu_qualifications = [
    "ND",
    "NCE",
    "HND",
    "Bachelor's Degree",
    "Master's Degree",
    "Doctorate (Ph.D.)",
    "Postgraduate Certificate",
  ];

  

  return (
    <div className="outer-con h-fit">

      <Loader
       OpenLoader={OpenLoader}
      /> 
      <div className="form-con">
        <form>
          <InputLabel id="edu-input">
            Highest Educational Qualification
          </InputLabel>
          <FormControl sx={{display:"flex", flexDirection:"row"}} fullWidth>
          <Select
            label="Highest Educational Qualification"
            labelId="edu-input"
            id="edu-select"
            fullWidth
            value={formData.highestEdu}
            onChange={handleInputChange("highestEdu")}
            // onDoubleClick={HandleUpdate}
             disabled={check === "Agreed" ? true : false}
          >
            {edu_qualifications.map((state, index) => (
              <MenuItem key={index} value={state}>
                {state}
              </MenuItem>
            ))}
          </Select>
          <Button
                  variant="contained"
                  color="primary"
                  onClick={HandleUpdate}
                  sx={{marginLeft:"1em"}}
                disabled={check === "Agreed" ? true : false}
                >
                  Save
                </Button>
          </FormControl>
          <p className="text-green-900 font-bold italic text-sm">
            Click to save Details
          </p>
          <InputLabel id="cv" sx={{ marginTop: "20px" }}>
            {formData.highestEdu !== "Postgraduate Certificate"
              ? `Upload Your ${formData.highestEdu} Certificate`
              : `Upload Your ${formData.highestEdu}`}
          </InputLabel>
          <div>
          <TextField
            id="cv"
            type="file"
            fullWidth
            variant="outlined"
            onChange={handleFileChange}
            inputProps={{
              accept: ".doc,.docx,.pdf,.txt,.xls,.xlsx,.ppt,.pptx,.png,.jpg,.jpeg,.gif,.svg,.zip,.rar,.7z",
              multiple: true // if you want to allow multiple file selection
            }}
          disabled={check === "Agreed" ? true : false}
          
          />
           {
             Person !== null && <>
              { Person.applicant_edu[0].resume_file ?
              
              <span style={{color:"green",fontWeight:"800",marginTop:"1em"}}>File Uploaded</span>
                 :

                 <span style={{color:"red",fontWeight:"800",marginTop:"1em"}}>File Not Upload</span>
              }
              
               
             </>
           }
          

          </div>
          <Button sx={{marginTop:"1em"}} variant="contained" onClick={handleUpload}>
        Upload
        </Button>
        </form>
      </div>
    </div>
  );

}

export default EducationStep;
