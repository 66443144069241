import { Button } from "@mui/material";
import React from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

function AwardLetter() {
  return (
    <div className="h-screen flex items-center justify-center">
      <Button variant="contained" endIcon={<CloudDownloadIcon />}>Download</Button>
    </div>
  );
}

export default AwardLetter;
