import React, { useState,useContext,useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import { toast } from "react-toastify";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";


function AddUnit() {



  
  const [formData, setFormData] = useState({
    localGovt: "",
    ward: "",
    unit: "",
  });
  const {accesstoken} = useContext(ContextData) 

  const [newUnit, setNewUnit] = useState([]);
  const [newWards, setNewWards] = useState([]);
  const [newLocalGovts, setNewLocalGovts] = useState(null);
  const [WardLists, setWardLists] = useState(null);
  const [UnitLists, setUnitLists] = useState(null);
  const [FoundedWards,setFoundedWards] = useState(null)
  const [Reload,setReload] = useState(true)


  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_wards`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setWardLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 

  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_unit`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setUnitLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  useEffect(()=>{

      const finded = WardLists !== null && WardLists.filter( item =>  item.add_local_gov ===  formData.localGovt  )
    

       if(finded){
        setFoundedWards(finded)
       }else{
        setFoundedWards(null)
       }
       
  
  },[formData.localGovt])


  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };




  const handleDelete = (index) => {
    const updatedUnit = newUnit.filter((_, i) => i !== index);
    setNewUnit(updatedUnit);
  };


  const DeleteUnit = async(token) =>{


    try {
      const response = await axios.delete(`${baseUrl}/auth/delete_unit_gov/${token}`, {
        withCredentials: true,
        headers: {
           'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      });
  
      toast.warn(response.data.statusText);
      setReload(!Reload)
    
    } catch (err) {
      if (err) console.log(err.message);
      // setLoader(false)
    }


  }



  const handleAdd = () => {
   
    // Check for empty fields
    const isEmptyField = Object.values(formData).every((value) => value === "");

    if (isEmptyField) {
      toast.success("Please fill out all fields");
    } else {

      axios.post(`${baseUrl}/auth/add_unit`,formData,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
           'authorization': `Bearer ${accesstoken}`
        },
      })
      .then((res) => {
     
            toast.success(res.data)
         //   setReload(!Reload)
           // setFormData({  unit: "" });
      })
      .catch((err) => {
        if (err) return console.log(err.message);
      });

      
    
    }
  };


  
  return (
    <div className="add-localGovt">
      <div>
        <div className="input-con">
          <TextField
            select
            label="Select Local Government"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.localGovt}
            onChange={handleInputChange("localGovt")}
          >
             {
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>

          <TextField
            select
            label="Add Ward"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.ward}
            onChange={handleInputChange("ward")}
          >
             {
            FoundedWards !== null && FoundedWards.map((list,index)=>(

              <MenuItem key={index} value={list.add_ward_gov}>{list.add_ward_gov}</MenuItem>

            ))
          }
          </TextField>

          <TextField
            label="Add Unit"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.unit}
            onChange={handleInputChange("unit")}
          />
 
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleAdd}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="list-con">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Local Government
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Ward
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Unit
                </TableCell>

             
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody> 
              {UnitLists !== null && UnitLists.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {item.add_local_gov}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {item.add_ward_gov}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {item.add_unit_gov}
                  </TableCell>
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    <Button
                      className="Hover-"
                      // onClick={() => HanldeRemove(user.st_tokens)}
                      elevation={10}
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: "rgb(199, 1, 1)",
                      }}
                      size="small"
                      variant="contained"
                      onClick={()=>DeleteUnit(item.tokens)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AddUnit;
