import React, { useContext, useEffect, useState,useMemo } from "react";
import {
  Avatar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import { ContextData } from "../context/Context";
import ViewDialog from "../Dashboard/Applicants/ViewDialog";
import baseUrl from "../../Route/BaseURL";
import axios from "axios";
import NunberCounter from "../Dashboard/Applicants/NunberCounter";
import { toast } from "react-toastify";
import validator from "validator";



const Post = () => {

  
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [Reload,setReload] = useState(true)
  const [CountData, setCountData] = useState(null);
  const [ApplicantData,setApplicantData] = useState(null)
  const [Count,setCount] = useState(0)
  const rowsPerPage = 6;
  const { setLoader, accesstoken,formDataFilter,ReloadApplicant_At_Filter,PersonUser,Checker} = useContext(ContextData);


   
      

  useEffect(() => {

    async function FetchData() {

      //  setLoader(true)
      
        if(Checker === "Administrator"){

          try {
            const response = await axios.get(`${baseUrl}/auth/get_awarded_applicant_ready_for_post/all `, {
              withCredentials: true,
              headers: {
                 'Accept': "application/json",
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }
            });
    
            setCountData(Object.keys(response.data));
            setApplicantData(response.data);
            setCount(response.data.length)
              
            // setLoader(false);
          } catch (err) {
            if (err) console.log(err.message);
            // setLoader(false)
          }

        }

        if(Checker === "Electrol Officer"){

          try { 
            const response = await axios.get(`${baseUrl}/auth/get_awarded_applicant_ready_for_post/${PersonUser.e_o_local_gov} `, {
              withCredentials: true,
              headers: {
                 'Accept': "application/json",
                'authorization': `Bearer ${accesstoken !== null && accesstoken}`
              }
            });
    
            setCountData(Object.keys(response.data));
            setApplicantData(response.data);
            setCount(response.data.length)
              
            // setLoader(false);
          } catch (err) {
            if (err) console.log(err.message);
            // setLoader(false)
          }

        }

    }
    FetchData();
  },[]);



  const filteredApplicants = useMemo(() => {

    return ApplicantData ? ApplicantData.filter(list => list.submit_status) : [];

  }, [ApplicantData]);

  const countallApplicants = filteredApplicants.length;

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };




  const List = Object.keys(ApplicantData !== null && ApplicantData).map(
    (data) => ApplicantData[data]
  );
  
 
   const filteredUsers = List.filter((user) => {
    
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    let fullName = user.fname +" "+ user.lname
    let post = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("(")+1)

       

           return ( 

            user.applcnt_ward.toLowerCase().includes(lowerCaseSearchText) ||
            post.toLowerCase().includes(lowerCaseSearchText)  ||
            fullName.toLowerCase().includes(lowerCaseSearchText) ||
            user.fname.toLowerCase().includes(lowerCaseSearchText) ||
            user.lname.toLowerCase().includes(lowerCaseSearchText) ||
            user.email.toLowerCase().includes(lowerCaseSearchText) ||
            user.r_ID.toLowerCase().includes(lowerCaseSearchText)  
            
            )
 



  });
  
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const indexOfLastUser = currentPage * rowsPerPage;

  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };




  return (
    <div className="con-dev">
      <Container>
        <Typography 
          variant="h4"
          gutterBottom
          className="flex justify-between items-center applicants_header"
        >
          <p className="font-extrabold mt-3 flex">Post to Unit:  <NunberCounter  countallApplicants={Count} /> </p>
           <p><b className="text-center" > {PersonUser.usertype} of {PersonUser.e_o_local_gov} </b></p>
          <div className="flex gap-2 justify-center  overflow-auto applicants_header-btns ">

          </div>
        </Typography>

        <div className="applicants_search-con flex items-center justify-center gap-4">
          <TextField
            label="Search BY FirstName, LastName,Registration ID, Ward, Email ,Position"
            variant="outlined"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            fullWidth
            margin="normal"
          />
         {/*  <FilterDialog /> */}
       
        </div>
    <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Full Name
                </TableCell>
              
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Local Government
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Ward
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Position
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  View
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Status
                </TableCell>
             
              </TableRow>
            </TableHead>
              {
                ApplicantData === null && <TableBody>No Data</TableBody>
              }
            <TableBody>
               {
                ApplicantData === null && <div style={{width:"90%",height:"50%"}}  className=" text-center flex justify-center items-center absolute"><p>Loading Applicants List...</p></div>
               }
              {ApplicantData !== null && usersToShow.map((user, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.fname}
                  </TableCell>
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.local_gov}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.applcnt_ward}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("("))}
                    
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap",display:"flex", justifyContent:"center",alignItems:"center" }}>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      <ViewDialog type="post" data={user} />
                    </TableCell>
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>

                    {
                       user.posted_applicant === "posted" ?  <>{(user.posted_applicant === "posted" ) &&   <p className="bg-green-950 text-white font-bold">Posted</p>}</> 
                       :
                       <>{(user.selected_applicant_status === 1 && user.awarded === 1 ) &&   <p className="bg-blue-400 text-white font-bold">Awarded</p>}</>  
                    }
                  
                  
                    
                   
                  </TableCell> 


               
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
        {totalPages > 1 && (
          <div>
            {currentPage > 1 && (
              <Button
                className="Hover-"
                onClick={handlePrevPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px"
                }}
                size="large"
                variant="contained"
              >
                <ArrowBackIcon />
              </Button>
            )}
            <span>
              Page {currentPage} of {totalPages}
            </span>
            {currentPage < totalPages && (
              <Button
                className="Hover-"
                onClick={handleNextPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px",
                }}
                size="large"
                variant="contained"
              >
                <ArrowForwardIcon />
              </Button>
            )}
             <span className="ml-10">
              Total result is : { (rowsPerPage * totalPages).toLocaleString()} {}
            </span>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Post;
