import axios from "axios";
import React, { useContext, useState } from "react";
import { ContextData } from "./context/Context";
import { useNavigate } from 'react-router-dom';
import baseUrl from "../Route/BaseURL";
import AlertPOP from "../Route/AlertPOP";
import Loader from "../Route/Loader";

function Login() {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const { setTokens,setApplicantTokens,setaccesstoken,Checker} = useContext(ContextData);
  const Navigate = useNavigate()

  const [message, setmessage] = useState("");
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  let usertype = ["applicant","Administrator","manage","Electrol Officer","Selector","Account Helper"]
  

  // Handle the alert transition and function

  const handleClick = (Transition) => {
    setTransition(() => "TransitionLeft");
    setOpen(true);
  };
  // Handle the close alert box
  const handleClose = () => {
    setOpen(false);
  };

  // Handle the submit function
  const HandleSubmit = (e) => {
    // Handle the refresh behavior
    e.preventDefault();

    // Call alert box function
    handleClick("left");

    if (Username.trim() === "" || Password.trim() === "") {
      setmessage("No Empty input allow");
      return;
    }

    const data = {
      email: Username,
      passwrd: Password,
    };

    axios.post(`${baseUrl}/pub/admin_u_login`,data,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data.text)
        if (res.data.status) {
          setmessage(res.data.text);
          setApplicantTokens(res.data.Usertoken)
          setaccesstoken(res.data.accesstoken)
         
          if(Checker === usertype[1]){
           Navigate("/dashboard/applicants")
          }else if(Checker === usertype[4]){
            Navigate("/selector/applicants")
          }else if(Checker === usertype[5]){
            Navigate("/account-helper/account-helper")
          }else if(Checker === usertype[3]){
            Navigate("/electoral_officer/post")
          }
             const timer = setTimeout(()=>{
              setmessage("Provide your login details again and click login");
              clearTimeout(timer)
              },300)
 


          }

        if (!res.data.status) {
          setmessage(res.data.text);
        }
      })
      .catch((err) => {
        if (err) return console.log(err.message);
      });
  };

  return (
    <div className=" h-screen w-full flex items-center justify-center flex-col login-con-bg">

      <AlertPOP
        Open={open}
        handleClose={handleClose}
        transition={transition}
        message={message}
      />
      <Loader />

      <div
        style={{ backgroundColor: "rgba(0,0,0,0.679)" }}
        className="flex flex-col items-center justify-center p-5 rounded-md text-cyan-50"
      >
        <h1 className="text-4xl font-extrabold mb-5">LOGIN</h1>
        <form className="flex flex-col w-96" onSubmit={HandleSubmit}>
          <div class="flex flex-col">
            <label for="inputEmail4" class="form-label font-bold">
              Email
            </label>
            <input
              type="email"
              className="form-control font-bold"
              id="inputEmail4"
              value={Username}
              onChange={(e) => setUsername(e.target.value)}
              style={{color:"black"}}
            />
          </div>

          <div class="mt-2 flex flex-col">
            <label for="inputPassword4" className="form-label font-bold">
              Password
            </label>
            <input
              type="password"
              className="form-control font-bold"
              id="inputPassword4"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              style={{color:"black"}}
            />
          </div>

          <div className=" font-bold mt-5 flex justify-center items-center">
            <button
              type="submit"
              className="btn btn-primary text-gray-50 bg-gray-900 p-2 font-bold"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
