import React, { useContext } from "react";
import PopUpDialog from "./PopUpDialog";
import Application from "./Application";
import { ContextData } from "../context/Context";

function Portal() {

  const d = new Date();
  let Year = d.getFullYear();

  const { CheckNull } = useContext(ContextData);
  
  return (
    <div
      style={{ boxShadow: "0px 6px 10px rgba(0,0,0,0.379)", height: CheckNull }}
      className=" home-con grid grid-cols-12"
    >
      {/* Header */}

      {/* Section */}

      <div className="col-start-1 col-end-13 flex justify-center z-10">
        <div className="container w-full ">
          <div>
            <h3 className="text-4xl text-center m-5 mb-3">
              Kaduna State Independent Electoral Commission (Kad-Siecom) for the
              Recruitment of Election Officials (Adhoc Staff)
            </h3>
          </div>

          <div className="paragraph flex flex-col justify-center items-center ">
            <p className="text-center m-5">
              Welcome to the Kad-Siecom website for the Recruitment of Election
              officials for Kaduna state and Nigerian citizens as a whole who are
              interested in serving as Adhoc Staff for 2024 Local Government
              Council Election duties in Kaduna state.
            </p>
            <p className="text-center m-5">
            The Adhoc Staff recruitment for the 2024 Kaduna State Local Government Councils Election has closed as of September 15th, 2024. You can still log in, but please note that all applications are currently under review...
            </p>
          </div>

          {/*  */}

          <div className="w-full  flex justify-center items-center mb-5">
           <Application />   
          </div>
        </div>
      </div>

      <div className="footer-home text-center col-start-1 col-end-13 flex justify-center items-cente flex-col">
        <p className="font-extrabold">
          All right reserved   Kaduna State Government &copy; {Year}
        </p>
        <p className="">
          <a target="_blank" href="https://mmt-ng.com/">
            Developed By MastermindTech
          </a>
        </p>
      </div>
    </div>
  );
}

export default Portal;
