import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function IconDelete(props) {
  return (
    <Tooltip title={props.tooltipText}>
      <IconButton>
        <DeleteIcon sx={{color:props.tooltipColor, fontSize:props.tooltipIconSize}}   />
      </IconButton>
    </Tooltip>
  );
}
