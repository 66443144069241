import React from "react";

function APOList() {
  return (
    <div>
      <div className="flex justify-center items-center mt-2">
        <ul className="text-justify text-black list-disc">
          <li>Maybe a Civil servant on grade level 8-10</li>
          <li>Must have Bachelor / HND in any discipline or its equivalent
          </li>
          <li>
            Previous knowledge of Election Operations will be an added advantage
          </li>
        </ul>
      </div>
    </div>
  );
}

export default APOList;
