import * as React from "react";
import { useState } from "react";
import {
  MenuItem,
  Select,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
// import axios from "axios";
import { ContextData } from "../../context/Context";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import baseUrl from "../../../Route/BaseURL";
import validator from "validator";
import { toast } from "react-toastify";

// import ApplicantProfileTabs from "../ApplicantProfile/ApplicantProfileTabs";
// import Avatar from "@mui/material/Avatar";

export default function PostDialog(props) {



  const [open, setOpen] = React.useState(false);
  const {accesstoken} = React.useContext(ContextData) 

  const handleClickOpen = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
  };



 
  const [formData, setFormData] = useState({
    localGovt: props.data.local_gov || "",
    ward: "",
    unit: "",
  });

  const [newUnit, setNewUnit] = useState([]);
  const [newWards, setNewWards] = useState([]);
  const [newLocalGovts, setNewLocalGovts] = useState(null);
  const [WardLists, setWardLists] = useState(null);
  const [UnitLists, setUnitLists] = useState(null);
  const [FoundedWards,setFoundedWards] = useState(null)
  const [Reload,setReload] = useState(true)
  const [FoundedUnits,setFoundedUnits] = useState(null)

    // Unit
    const [Unit,setUnit] = useState("")
    const [Unit_token_limit,setUnit_token_limit] = useState("")
    const [ward,setward] = useState("")
    const [local_gov,setlocal_gov] = useState("")

  
    const handleInputChange = (field) => (e) => {
      setFormData({ ...formData, [field]: e.target.value });
    };

  React.useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  React.useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_wards`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setWardLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 

  React.useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_unit`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setUnitLists(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 



/*   React.useEffect(()=>{

      const finded = WardLists !== null && WardLists.filter( item =>  item.add_local_gov ===  formData.localGovt  )
    

       if(finded){
        setFoundedWards(finded)
       }else{
        setFoundedWards(null)
       }
       
  
  },[formData.localGovt]) */


  const handlreload = () =>{

         
      const finded = WardLists !== null && WardLists.filter( item =>  item.add_local_gov ===  props.data.local_gov  )
    

      if(finded){
       setFoundedWards(finded)
      }else{
       setFoundedWards(null)
      }


  }
    

  React.useEffect(()=>{

    const finded = UnitLists !== null && UnitLists.filter( item =>  item.add_ward_gov ===  formData.ward  )
  
     
  
     if(finded){
      setFoundedUnits(finded)
     }else{
      setFoundedUnits(null)
     }
     
  
  },[formData.ward])


/* Filter Unit */

React.useEffect(()=>{    
  
  const finded = UnitLists !== null && UnitLists.find( item =>  item.tokens ===  formData.unit.slice(formData.unit.indexOf("=") + 1) )
  // console.log(finded)
   if(finded){
      
     setUnit_token_limit(finded.tokens)
     setlocal_gov(finded.add_local_gov)
     setward(finded.add_ward_gov)
     setUnit(finded.add_unit_gov)
    
    
   
    }else{

      setUnit("") 
      setUnit_token_limit("")
      setlocal_gov("")
      setward("")
    }



},[formData.unit])



const Handle_Post = () =>{

     
  if(validator.isEmpty(Unit.toString()) || validator.isEmpty(Unit_token_limit)) return toast.warn("input can't be empty")
    
    const data = {Unit,Unit_token_limit,local_gov,ward,user_token: props.data.user_token,choose: 1}

    axios.put(`${baseUrl}/auth/post_unit`,data,{
      withCredentials: true,
      headers: {
        Accept: "application/json",
         'authorization': `Bearer ${accesstoken}` 
      },
    })
    .then((res) => {
   
          toast.success(res.data)
         setReload(!Reload)
         
    })
    .catch((err) => {
      if (err) return console.log(err.message);
    });

 

}





  return (
    <div className="appicant-profile-dialog-con w-500rem bg-green-800">
      <Button
        variant="contained"
        onClick={handleClickOpen}
        sx={{ whiteSpace: "nowrap", background: "green", padding: ".8rem" }}
      >
        Post
        <SendIcon
          tooltipText="Post Applicant"
          tooltipColor="white"
          tooltipIconSize="19px"
          sx={{marginLeft:"4px"}}
        />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="appicant-profile-dialog-con"
      >
        <DialogContent>
        
          {props.data.local_gov}
        <div className="input-con">
           <TextField
            select
            label="Select Local Government"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.localGovt}
            onChange={handleInputChange("localGovt")}
            disabled
          >
            { 
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>

        
          
          <TextField
            select
            label="Wards"
            variant="outlined"
            fullWidth
            margin="normal"
            name="ward"
            value={formData.ward}
            onChange={handleInputChange("ward")}
            onMouseEnter={handlreload}
          >
             {
             FoundedWards !== null && FoundedWards.map((list,index)=>(

              <MenuItem key={index} value={list.add_ward_gov}>{list.add_ward_gov}</MenuItem>

            ))
          }
          </TextField>



          <TextField
            select
            label="Units"
            variant="outlined"
            fullWidth
            margin="normal"
            name="unit"
            value={formData.unit}
            onChange={handleInputChange("unit")}
          >
             {
             FoundedUnits !== null && FoundedUnits.map((list,index)=>(

              <MenuItem key={index} value={list.add_unit_gov+"="+list.tokens}>{list.add_unit_gov}</MenuItem>

            ))
          }
          </TextField>

      </div>

         <div className=" flex justify-center items-center w-full flex-col">
           <p className="shadow-2xl text-2xl"><b>Post</b></p>
          <Button
        variant="contained"
        onClick={Handle_Post}
        sx={{ whiteSpace: "nowrap", background: "green", padding: ".8rem" }}
      >
        <SendIcon
          tooltipText="Post Applicant"
          tooltipColor="white"
          tooltipIconSize="19px"
        />
      </Button>

         </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
