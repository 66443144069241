import React from "react";
import { useNavigate } from "react-router-dom";

function PositionsCard() {
  const Navigate = useNavigate();
  return (
    <div>
      <h1 className="text-center text-xl mb-4">
        JOB POSITIONS AND DESCRIPTIONS{" "}
      </h1>

      <div className="positions_cards">
        <div className="card">
          <p className="card_title text-green-700">
            Supervisory Presiding Officer (SPO)
          </p>
          <ul className=" text-black list-disc">
            <li>Shall assist in the training of Poll officials.</li>
            <li>
              Shall receive all Election materials from the Electoral Officer on
              the eve of Elections.
            </li>
            <li>
              Shall distribute and retrieve all Election materials to the
              Presiding Officers under his area of supervision and have
              sufficient knowledge of PUs under his/her supervision.
            </li>
          </ul>
        {/*   <p className="text-left mt-4 italic">
            Click{" "}
            <span
              className=" text-blue-700 underline cursor-pointer"
              onClick={() => Navigate("/portal")}
            >
              here
            </span>{" "}
            to check eligibility and apply{" "}
          </p> */}
        </div>
        <div className="card">
          <p className="card_title text-green-700">Presiding Officer (PO)</p>
          <ul className=" text-black list-disc">
            <li>Shall be in-charge of the Polling Unit.</li>
            <li>
              Collect all Election materials from Electoral Officer through the
              Supervisory Presiding Officer (SPO) in the morning of the Election
              and properly document them.
            </li>
            <li>
              Prepares reports and submits to Supervisory Presiding Officer
              (SPO)
            </li>
          </ul>
          {/* <p className="text-left mt-4 italic">
            Click{" "}
            <span
              className=" text-blue-700 underline cursor-pointer"
              onClick={() => Navigate("/portal")}
            >
              here
            </span>{" "}
            to check eligibility and apply{" "}
          </p> */}
        </div>
        <div className="card">
          <p className="card_title text-green-700">
            Assistant Presiding Officer (APO)
          </p>
          <ul className=" text-black list-disc">
            <li>
              Shall be In-Charge of the Polling Unit in the absence of the
              Presiding Officer shall also handle the Voters Register for
              accreditation of Voters at the Polling Unit PU
            </li>
            <li>
              Shall assist the Presiding Officer to collect all Election
              materials from the Supervisory Presiding Officer SPO in the morning of the
              Election and properly document them.
            </li>
            <li>
              Shall educate the Voters on the Election process and arrangement
              of Polling Unit PU.
            </li>
          </ul>
          {/* <p className="text-left mt-4 italic">
            Click{" "}
            <span
              className=" text-blue-700 underline cursor-pointer"
              onClick={() => Navigate("/portal")}
            >
              here
            </span>{" "}
            to check eligibility and apply{" "}
          </p> */}
        </div>
        <div className="card">
          <p className="card_title text-green-700">Poll Clerk (PC)</p>
          <ul className=" text-black list-disc">
            <li>Work under the supervision of the Presiding Officer (PO)</li>
            <li>
              Ensure that there is orderly arrangement of the Voters at Polling
              Unit (PU) always.
            </li>
            <li>Assist in Ticking the names of the Voters on the Register</li>
            <li>
              Assist the Assistant Presiding Officer in checking the names of
              Voters from the Voters Register and ascertain their names are in
              the register
            </li>
          </ul>
          {/* <p className="text-left mt-4 italic">
            Click{" "}
            <span
              className=" text-blue-700 underline cursor-pointer"
              onClick={() => Navigate("/portal")}
            >
              here
            </span>{" "}
            to check eligibility and apply{" "}
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default PositionsCard;
