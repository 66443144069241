import * as React from "react";
import Button from "@mui/material/Button";
import { useState } from "react";
import { toast } from "react-toastify";
import baseUrl from "../../../Route/BaseURL";
import Context, { ContextData } from "../../context/Context";
import axios from "axios";
import validator from "validator";
import Loader from "../../../Route/Loader";


export default function AcknowledgementStep() {


  const [isChecked, setIsChecked] = useState(false);
  const [agreed, setAgreed] = useState(null);
  const {Person,setReloadPersonData,ReloadPersonData,accesstoken}  =  React.useContext(ContextData)
  const check = Person !== null && Person.applicant[0].submit_status
  const [OpenLoader,setOpenLoader] = useState(false)

  function handleSubmit() {

    isChecked ? setAgreed("Agreed") : setAgreed("Not Agreed");
    let ref_tokens = Person !== null && Person.applicant[0].ref_tokens
    let ID = Person !== null && Person.applicant[0].r_ID
    let email = Person !== null && Person.Sign_Up[0].email
    let fname = Person !== null && Person.applicant_bio[0].fname


    
      
     
    

          
    if (agreed !== null) {

      if(Person.applicant_bio[0].picture === null){

        toast.warn("Please upload your PassPort Photo at Bio Data Section")
        return

      }

          if(Person.applicant_demography[0].applcnt_address === null){
                
             toast.warn("Please fill out all fields at Demographic Section")
             return
                
          }
          if(Person.applicant_edu[0].highst_edu === null || Person.applicant_edu[0].resume_file === null){

            toast.warn("Please fill out all fields at Education Section")
            return

               
          }

          if(Person.applicant_wrk_experience[0].years_of_wrk === null){

            toast.warn("Please fill out all fields at Work Experience Section")
            return
               
          }

     
             
       
          if(Person.applicant_application[0].person_with_spcl_need === null){

            toast.warn("Please fill out all fields at Application Section")
            return

          }



          
                     
          if(Person.applicant_application[0].person_with_spcl_need === "Yes" && Person.applicant_application[0].type_of_disability === null){

            

               toast.warn("Please fill out all fields and type the kind of Disability at Application Section")
               return

            
            }


            if(Person.bank_details[0].bank_name === null || Person.bank_details[0].account_name  === null|| Person.bank_details[0].account_number === null){

              toast.warn("Please fill out all fields at Bank Details Section")
              return
  
            }


          
             
    const data = {
      ID,
      email,
      fname,
      ref_tokens,
      agreed
}
            


                setOpenLoader(true)
                axios.put(`${baseUrl}/auth/update_submit_status/`,data,{
                  withCredentials: true,
                  headers: {
                    'Accept': "application/json",
                    'authorization': `Bearer ${accesstoken !== null && accesstoken}`
                  },
                }).then(res=>{    
                  setOpenLoader(false)
                  toast.success(res.data)
                  setReloadPersonData(!ReloadPersonData)
                 })
                .catch((err) => {
                  if (err) return console.log(err.message);
                  setOpenLoader(false)
                })
            
                 
                
 
              }
  


  }

  return (
    <div className="outer-con h-fit">

      <Loader
       OpenLoader={OpenLoader}
      />
      <div className="form-con">
        <div className="text_con">
          <h4 className="text-center text-2xl mb-2">Acknowledgement Statement</h4>
          
          <p style={{whiteSpace:"pre-line"}}>
I, <b>{Person !== null && Person.applicant_bio[0].fname}</b>, hereby affirm that all the information provided in this application is true, accurate, and complete to the best of my knowledge. I understand that any false representation, impersonation, or provision of incorrect information will result in the automatic disqualification of my application and may lead to further legal consequences as deemed appropriate by the Kaduna State Independent Electoral Commission (Kad-Siecom).
 <p className="pt-2">

By submitting this application, I fully accept and agree to adhere to the rules and regulations governing the recruitment process and the responsibilities associated with the role I am applying for.

 </p>  
          </p>
        </div>
        <div className="checkbox_con">
          <input
            type="checkbox"
            className="checkbox_btn"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          />
        </div>
        <div className="submit_btn-con flex items-center justify-end">


            {
              check === null ?  
               <Button
              variant="contained"
              disabled={!isChecked}
              onClick={handleSubmit}
              
            >
              Submit
            </Button>  :
          <Button
            variant="contained"
            disabled
           
             >
            submitted
          </Button>
            }
        </div>
      </div>
    </div>
  );
}
