import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ContextData } from "../context/Context";

function Settings() {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const Navigate = useNavigate()
  const [Changeroutes,setChangeroutes] = React.useState(null)

  
  const {Checker} = useContext(ContextData)



    useEffect(()=>{


        if(Checker === "Administrator"){

          setChangeroutes( [
            {
              path: "add-local-government",
              name: "Add Local Government",
            },
        
            {
              path: "add-ward",
              name: "Add Ward",
            },
        
            {
              path: "add-unit",
              name: "Add Unit",
            },
        
            {
              path: "open-application",
              name: "Open-Application",
            },
        
            {
              path: "add-position",
              name: "Add Position",
            },
        
            {
              path: "limit-applicants",
              name: "Limit Applicants",
            },
        
            {
              path: "update-password",
              name: "Update Password",
            },
          ])


        }else{

          setChangeroutes( [
           
            {
              path: "update-password",
              name: "Update Password",
            }
          ])

        }





    },[Checker])
 

  return (
    <div className="award-flex-con w-full flex h-full">
      <div
        style={{ height: "" }}
        className="award w-1/4 bg-slate-100 h-screen overflow-auto"
      >
        <ul className="ver-aside-ul  ">
          {Changeroutes !== null && Changeroutes.map((item, index) => (
            <li key={index} className="flex justify-between items-center"
            onClick={() => {
                Navigate(item.path);
              }} >
              <span className="p-3"> {item.name}</span>{" "}
            </li>
          ))}
        </ul>
      </div>

      <div style={{ height: "95vh" }} className="batch w-3/4  overflow-auto">
        <Outlet />
      </div> 
    </div>
  );
}

export default Settings;
