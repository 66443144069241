import React from "react";

function POList() {
  return (
    <div>
      <div className="flex justify-center items-center mt-2">
        <ul className="text-justify text-black list-disc">
          <li>Maybe a Civil servant on grade level 10-12</li>
          <li>Bachelor / HND or equivalent</li>
          <li>
            Previous knowledge of Election Operations will be an added
            advantage.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default POList;
