import React from "react";

function BioData(props) {
  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Bio Data </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        <ul>
          
            <li><b>First Name:</b> <span>{props.data.fname} </span></li>
            <li><b>Last Name:</b> <span>{props.data.lname}</span></li>
            <li><b>Date Of Birth:</b> <span>{props.data.d_of_birth}</span></li>
            <li><b>Gender:</b> <span> {props.data.gender} </span></li>
            <li><b>Email:</b> <span>{props.data.email}</span></li>
            <li><b>Phone Number:</b> <span>{props.data.mobile}</span></li>

        </ul>

      </div>

      
    </div>
  );
}

export default BioData;
