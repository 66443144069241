import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import RegisterForm from "./RegisterForm";
import { ContextData } from "../context/Context";
import axios from "axios";
import baseUrl from "../../Route/BaseURL";
import ApplicantLogin from "./ApplicantLogin";

export default function PopUpDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [QualificationList, setQualificationList] = React.useState(null);
  const { AdministratorTokens, UpdateAmount,OpenAppstatus } = React.useContext(ContextData);
  const [changeform, setchangeform] = React.useState("home");

  const { CheckEligibility, setCheckEligibility } =
    React.useContext(ContextData);

  // Get List of Qualification from the server



  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // Display back the buttons
  const DisplayForms = () => {
    setchangeform("home");
  };
  // Display Login Form
  const DisplayLoginForm = () => {
    setchangeform("login");
  };

  // Display Signup Form
  const DisplaySignupForm = () => {
    setchangeform("signup");
  };

  return (
    <React.Fragment>
      {/* the v  is 0 not 1 */}
      <Button
        variant="contained"
        disabled={(OpenAppstatus !== null && OpenAppstatus === 1)  ? !CheckEligibility : false}
        onClick={handleClickOpen("paper")}
      >
        {
          (OpenAppstatus !== null && OpenAppstatus === 1) ? "APPLY NOW" : "  Click to Login"
        }
      
      
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        id="portal-login_dialogCon"
      >
        <div className="flex justify-between">
          <DialogTitle id="scroll-dialog-title">
            {changeform === "home" && <b>SIECOM</b>}
            {changeform === "login" && <b>Login</b>}{" "}
            {changeform === "signup" && <b>Sign Up</b>}
          </DialogTitle>
          <img src="./assets/dbimgs/kd.png" className="w-14 mr-1 shadow-xl" />
        </div>
        <DialogContent dividers={scroll === "paper"}>
          <div
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            className="mb-10"
          >
          
            {changeform === "home" && (
              <div className="portal_dialog-con flex flex-col w-96 h-80 justify-center items-center">
                <img src="./assets/siecom.png" className="w-44 mb-3" />
                 {
                  OpenAppstatus !== null && 
                  <>
                  
                     {
                     OpenAppstatus === 1 &&
                        <>
                        <Button
                          onClick={DisplaySignupForm}
                          type="submit"
                          sx={{ marginBottom: "0.8em", width: "100%" }}
                          variant="contained"
                          color="primary"
                        >
                          <span className="font-extrabold">create account</span>
                        </Button> 
                        <span className="font-extrabold">
                          <AutorenewIcon sx={{ fontWeight: "800" }} />
                        </span> 
                        
                        </>

                     }
                  </>
                 }
                <Button
                  onClick={DisplayLoginForm}
                  sx={{ marginTop: "0.8em", width: "100%" }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  <span className="font-extrabold">Login</span>
                </Button>
              </div>
            )}

            {
              OpenAppstatus !== null && 
              <>
               {
                OpenAppstatus === 1 && 
                <> 
                 {changeform === "signup" && (
              <RegisterForm
                QualificationList={
                  QualificationList !== null && QualificationList
                }
              />
               )}
                
                </>
               }
              </>
            }

 

            {changeform === "login" && (
              <ApplicantLogin
                QualificationList={
                  QualificationList !== null && QualificationList
                }
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex  w-full justify-between items-center">
            {changeform !== "home" && (
              <Button
                onClick={DisplayForms}
                sx={{ marginBottom: "0.8em" }}
                variant="contained"
                color="primary"
              >
                <span className="font-extrabold">
                  <ArrowBackIcon />
                </span>
              </Button>
            )}

            <h3 className="text-1xl font-extrabold">Progressive form</h3>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
