import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import axios from "axios";
import baseUrl from "../../../Route/BaseURL"
import AlertPOP from "../../../Route/AlertPOP";
import { ContextData } from "../../context/Context";
import { toast } from "react-toastify";




export default function AddUserDialog() {



  const [open, setOpen] = React.useState(false);
  const {RefreshUesFF,setRefreshUesFF,accesstoken} = React.useContext(ContextData)
  const [newLocalGovts, setNewLocalGovts] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Form start---------->

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    userType: "",
    localGovt:""
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const [message,setmessage] = useState("")
  const [openAlert, setOpenAlert] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [Reload,setReload] = useState(true)
 

  // Handle the alert transition and function
  const handleClick = (Transition) => {
   setTransition(() => "TransitionLeft")
    setOpenAlert(true);
  };
   // Handle the close alert box
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };


  React.useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 



  // Handle submit
  const handleSubmit = (e) => {

    e.preventDefault();

      // Call Alert Box function
      handleClick("left")

    // Check for empty fields
    const isEmptyField = Object.values(formData).every((value) => value === "");

    if (isEmptyField) {
        
      setmessage("Please fill out all fields")
      return 

    } 

    
      // Submit the form (you can implement your submission logic here)

        //  console.log(formData)

      axios.post(`${baseUrl}/auth/register_administration`,formData,{
        withCredentials: true,
        headers: {
          'Accept' : 'application/json',
          'authorization': `Bearer ${accesstoken}`
        }}).then(res=>{

          
            if(res.data.status){

              setmessage(res.data.statusText)
              toast.success(res.data.statusText)
              setRefreshUesFF(!RefreshUesFF)

              setFormData({
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                userType: ""
              })
            }

            if(!res.data.status){
              setmessage(res.data.statusText)
              toast.warn(res.data.statusText)
            }
    
        }).catch(err=> {

            if(err) return  console.log(err)
           
        })


  }

 const  user_roles = [
          "Administrator",
          "Manage",
          "Finance Manager",
          "Selector",
          "Account Helper",
          "Electrol Officer",
          "Commissioner"
]


  //Form end --------->

  return (
    <React.Fragment>
    
      <Button variant="contained" onClick={handleClickOpen} className="admin-users_header-btn">
        Add Admin User
      </Button>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="py-10"
      >
        <AlertPOP
         Open={openAlert}
         handleClose={handleCloseAlert}
         transition={transition}
         message={message}
      />
        <DialogTitle id="alert-dialog-title">New Admin User</DialogTitle>
        <DialogContent>

         
            <form onSubmit={handleSubmit}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.firstName}
                onChange={handleInputChange("firstName")}
              />
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.lastName}
                onChange={handleInputChange("lastName")}
              />
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange("email")}
              />
              <TextField
                label="Mobile Number"
                type="tel"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.mobileNumber}
                onChange={handleInputChange("mobileNumber")}
              />



              <TextField
              select
              label="User Type"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.userType}
              onChange={handleInputChange("userType")}
              >
              {user_roles.map((userType) => (
              <MenuItem key={userType} value={userType}>
              {userType}
              </MenuItem>
              ))}
              </TextField>


              {
                formData.userType === "Electrol Officer" && 

                <TextField
                select
                label="Select Local Government"
                variant="outlined"
                fullWidth
                margin="normal"
                value={formData.localGovt}
                onChange={handleInputChange("localGovt")}
                
              >
                { 
                newLocalGovts !== null && newLocalGovts.map((list,index)=>(
                  <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>
                ))
              }
              </TextField>

              }
         
        


              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            </form>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
