import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import IndexDashboard from "../components/Pages/IndexDashboard";
import Post from "../components/Pages/Post";
import Portal from "../components/Portal/Portal";
import Login from "../components/Login";
import ApplicantForm from "../components/ApplicantForm";
import Home from "../components/Home/Home";
import Applicants from "../components/Pages/Applicants";
import Profile from "../components/Pages/ApplicantForm";
import Award from "../components/Pages/Award";
import AdminUsers from "../components/Pages/AdminUsers";
import Settings from "../components/Pages/Settings";
import AddLocalGovt from "../components/Dashboard/Settings/AddLocalGovt";
import AddWard from "../components/Dashboard/Settings/AddWard";
import AddUnit from "../components/Dashboard/Settings/AddUnit";
import AddPosition from "../components/Dashboard/Settings/AddPosition";
import LimitApplicants from "../components/Dashboard/Settings/LimitApplicants";
import Audit from "../components/Pages/Audit";
import Site from "../components/Pages/Site";
import HeroSection from "../components/Dashboard/Site/HeroSection";
import SectionOne from "../components/Dashboard/Site/SectionOne";
import SectionTwo from "../components/Dashboard/Site/SectionTwo";
import SectionThree from "../components/Dashboard/Site/SectionThree";
import SectionFour from "../components/Dashboard/Site/SectionFour";
import UpdatePassword from "../components/Dashboard/Settings/UpdatePassword";
import { ContextData } from "../components/context/Context";
import Contact from "../components/Contact";
import Overseer from "../components/Pages/Overseer";
import AwardLetter from "../components/Pages/AwardLetter";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import Page404 from "./Page404";
import AdminPrivateRoute from "./PrivateRoute/AdminPrivateRoute";
import ApplicantLandPage from "../components/Pages/ApplicantLandPage";
import OpenApplication from "../components/Dashboard/Settings/OpenApplication";
import SelectorPrivateRoute from "./PrivateRoute/SelectorPrivateRoute";
import AccountHelper from "../components/Pages/AccountHelper";
import AccountHelperPrivateRoute from "./PrivateRoute/ElectoralOfficerPrivateRoute";
import ElectoralOfficerPrivateRoute from "./PrivateRoute/ElectoralOfficerPrivateRoute";

function Router() {
        
    const {accesstoken,setaccesstoken,Checker,ApplicantTokens} = useContext(ContextData)
    // const sessionAccessToken = sessionStorage.getItem("accessToken")
     let usertype = ["applicant","Administrator","manage","Electrol Officer"]
   //  const Sessionusertype = sessionStorage.getItem('usertype');



  return (
    <Routes>

      <Route path="/" element={<Home />} index />

      
           
         <Route element={<AdminPrivateRoute /> } >
           
         <Route path="/dashboard" element={<IndexDashboard />}>

                <Route path="post" element={<Post />} />
                <Route path="applicants" element={<Applicants />}  />
               <Route path="award" element={<Award />} />
               <Route path="admin-users" element={<AdminUsers />} />
               <Route path="account-helper" element={<AccountHelper />} />
               <Route path="overseer" element={<Overseer />} />
               <Route path="settings" element={<Settings />}>
                 <Route path="add-local-government" element={<AddLocalGovt />} />
                 <Route path="add-ward" element={<AddWard />} />
                 <Route path="add-unit" element={<AddUnit />} />
                 <Route path="add-position" element={<AddPosition />} />
                 <Route path="limit-applicants" element={<LimitApplicants />} />
                 <Route path="update-password" element={<UpdatePassword />} />
                 <Route path="open-application" element={<OpenApplication />} />
               </Route> 
               <Route path="site" element={<Site />}>
                 <Route path="hero-section" element={<HeroSection />} />
                 <Route path="section-one" element={<SectionOne />} />
                 <Route path="section-two" element={<SectionTwo />} />
                 <Route path="section-three" element={<SectionThree />} />
                 <Route path="section-four" element={<SectionFour />} />
               </Route>
               <Route path="audit" element={<Audit />} />
         </Route> 
         </Route>


         {/* Electoral Officer */}
          
         <Route element={<ElectoralOfficerPrivateRoute /> } >
         <Route path="/electoral_officer" element={<IndexDashboard />}>
         <Route path="post" element={<Post />} />
         <Route path="settings" element={<Settings />}>
                 <Route path="update-password" element={<UpdatePassword />} />
               </Route> 
         
         </Route> 
         </Route>
         

         {/* Applicant DashBoard */}
         <Route element={<PrivateRoute /> } >
         <Route path="/application" element={<IndexDashboard />}>
         <Route path="appllication_form" element={<Profile />} />
         <Route path="applicantlandpage" element={<ApplicantLandPage />} />
         <Route path="award-letter" element={<AwardLetter />} />
         </Route>
         </Route>    

         {/* Selector route */}
         <Route element={<SelectorPrivateRoute /> } >

         <Route path="/selector" element={<IndexDashboard />}>

         <Route path="applicants" element={<Applicants />}  />

         <Route path="settings" element={<Settings />}>
                 <Route path="update-password" element={<UpdatePassword />} />
               </Route> 
         </Route> 

         </Route>

         {/* Account Helper */}

          <Route element={<AccountHelperPrivateRoute /> } >

          <Route path="/account-helper" element={<IndexDashboard />}>

          <Route path="account-helper" element={<AccountHelper />}  /> 

          <Route path="settings" element={<Settings />}>
          <Route path="update-password" element={<UpdatePassword />} />
          </Route> 
          </Route> 

          </Route>

       
     {/*   <Route path="*" element={<Navigate to="/"/>} /> */}
      <Route path="/portal" element={<Portal />} />
      <Route path="/login" element={<Login />}  />
     
      <Route path='/404' element={<Page404/>} />
      <Route path='*' element={<Navigate to="/404"/>}  />

       
      <Route path="/contact" element={<Contact />} />

      
    </Routes>
  );
}


export default Router;
