import React, { useContext, useEffect, useState,useMemo } from "react";
import {
  Avatar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
  MenuItem
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
// import axios from "axios";
import { ContextData } from "../context/Context";
// import baseUrl from "../../../Route/BaseURL";
import IconDelete from "../Dashboard/FontIcons/IconDelete";
import ViewDialog from "../Dashboard/Applicants/ViewDialog";
import baseUrl from "../../Route/BaseURL";
import axios from "axios";
import NunberCounter from "../Dashboard/Applicants/NunberCounter";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import validator from "validator";



const Applicants = () => {

  
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [Reload,setReload] = useState(true)
  const [CountData, setCountData] = useState(null);
  const rowsPerPage = 6;
  const [newLocalGovts, setNewLocalGovts] = useState(null);
  const [ReloadData,setReloadData] = useState(true)
  const [Selected,setSelected] = useState({
       selected: 0,
       awaiting: 0,
       rejected: 0,
       awarded: 0
  })
  const { setLoader, accesstoken,ApplicantData,setApplicantData,Count,setCount,formDataFilter,ReloadApplicant_At_Filter,Checker} = useContext(ContextData);
  const Navigate = useNavigate()
  const [FormDataFilter,setFormDataFilter] = useState(null)


/* Get filter */
useEffect(() => {
  async function FetchData() {
    //  setLoader(true)

    try {
      const response = await axios.get(`${baseUrl}/auth/get_filter_value`, {
        withCredentials: true,
        headers: {
           'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      });
  
      setFormDataFilter(response.data);
     
    
    } catch (err) {
      if (err) console.log(err.message);
      // setLoader(false)
    }

  }
  FetchData();
},[ReloadData]); 



useEffect(() => {

    async function FetchData() {

    //  setApplicantData(null);
    
      //  setLoader(true)
     // get_all_applicants
     // get_all_not_sub_applicant

      try {
        const response = await axios.get(`${baseUrl}/auth/get_all_applicants/${FormDataFilter !== null && FormDataFilter}`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken !== null && accesstoken}`
          },
        /*   params: {
            post : formDataFilter.post,
            ward: formDataFilter.ward,
            unit : formDataFilter.unit,
            limit: rowsPerPage,
            offset: (currentPage - 1) * rowsPerPage, // Fetch applicants with offset
          }, */
        })

        
        
        
        setCountData(Object.keys(response.data.applicant));
        setApplicantData(response.data.applicant);
        setCount(response.data.applicant.length)
        setSelected({...Selected,selected:response.data.selected,awarded:response.data.awardedValue,awaiting:response.data.awaitingValue,rejected:response.data.rejectedValue})
       
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload,ReloadApplicant_At_Filter,FormDataFilter]);


/* Get Local Gov */
  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }

    }
    FetchData();
  },[ReloadData]); 




  const HandleUpdateFilter = () =>{ 

           
       if (validator.isEmpty(FormDataFilter.toString()) ){
          toast.warn('Select filter value')
          return  
       } 
      const data = {lga : FormDataFilter}
       

    axios.put(`${baseUrl}/auth/update_filtervalue`,data,{
      withCredentials: true,
      headers: {
        'Accept': "application/json",
        'authorization': `Bearer ${accesstoken}`
      },
    }).then(res=>{ 
               
      toast.success(res.data)
     
     })
    .catch((err) => {
      if (err) return console.log(err.message);
   ///   setOpenLoader(false)
    })

    
 
}

  const filteredApplicants = useMemo(() => {

    return ApplicantData ? ApplicantData.filter(list => list.submit_status) : [];

  }, [ApplicantData]);

  const countallApplicants = filteredApplicants.length;

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };




  const List = Object.keys(ApplicantData !== null && ApplicantData).map(
    (data) => ApplicantData[data]
  );
  
 // selected_applicant_status
   const filteredUsers = List.filter((user) => {
    
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    let fullName = user.fname +" "+ user.lname
    let post_local = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("(")+1,user.type_of_position_applied.indexOf(")"))+"," + user.local_gov
    let post = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("("))
    let post_local_ward = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("(")+1,user.type_of_position_applied.indexOf(")"))+","+user.local_gov+","+user.applcnt_ward
    let post_selected = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("(")+1,user.type_of_position_applied.indexOf(")"))+","+user.selected_applicant_status
    let post_not_selected = user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("(")+1,user.type_of_position_applied.indexOf(")"))+","+user.selected_applicant_status+","+user.awarded
       

           return ( 

            post_local .toLowerCase().includes(lowerCaseSearchText) ||
            post.toLowerCase().includes(lowerCaseSearchText)  ||
            post_local_ward.toLowerCase().includes(lowerCaseSearchText)  ||
            fullName.toLowerCase().includes(lowerCaseSearchText) ||
            user.fname.toLowerCase().includes(lowerCaseSearchText) ||
            user.lname.toLowerCase().includes(lowerCaseSearchText) ||
            user.local_gov.toLowerCase().includes(lowerCaseSearchText) ||
            user.email.toLowerCase().includes(lowerCaseSearchText) ||
            user.r_ID.toLowerCase().includes(lowerCaseSearchText)   ||
            user.mobile.toLowerCase().includes(lowerCaseSearchText)  ||
            post_selected.toLowerCase().includes(lowerCaseSearchText)   ||
            post_not_selected.toLowerCase().includes(lowerCaseSearchText)   
            
            )
 



  });
  
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  const indexOfLastUser = currentPage * rowsPerPage;

  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const HanldeRemove = async (tokens,pic,cert) => {
 
       const data = {token:tokens,pic,cert}
      
   


      axios.put(`${baseUrl}/auth/remove_applicant`,data,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
           'authorization': `Bearer ${accesstoken}`
        },
      })
      .then((res) => {

        setReload(!Reload)
        toast.success(res.data.statusText)
      })
      .catch((err) => {
        if (err) return console.log(err.message);
      });



  };
  

  

  return (
    <div id="con-dev" className="con-dev">
      <Container id="con-dev">
        <Typography 
          variant="h4"
          gutterBottom
          className="flex justify-between items-center applicants_header"
        >
          <p className="font-extrabold mt-3 flex">Applicants:  <NunberCounter  countallApplicants={Count} /> </p>
           <p className="text-sm font-extrabold mr-1" >Total Awaiting:{Selected.awaiting.toLocaleString()}</p>
           <p className="text-sm font-extrabold mr-1">Total Awarded:{Selected.awarded.toLocaleString()}</p>
           <p className="text-sm font-extrabold mr-1">Total Selected:{Selected.selected.toLocaleString()}</p>
           <p className="text-sm font-extrabold mr-1">Total Rejected:{Selected.rejected.toLocaleString()}</p> 

          <div className="flex gap-2 justify-center  overflow-auto applicants_header-btns ">

            <div className="flex justify-center items-center ">

            <label className="text-sm font-extrabold mr-1">Filter:</label> 
          <TextField
            select
            variant="outlined"
            fullWidth
            margin="normal"
            value={FormDataFilter}
            onChange={(e)=> setFormDataFilter(e.target.value)}
             sx={{width:"6em"}}
          >
              <MenuItem value="All">All</MenuItem>
             {
            newLocalGovts !== null && newLocalGovts.map((list,index)=>(

              <MenuItem key={index} value={list.add_local_gov}>{list.add_local_gov}</MenuItem>

            ))
          }
          </TextField>
            </div>
            {
              Checker !== null && <>
              
              {
              Checker === "Administrator" && 

                <Button onClick={HandleUpdateFilter} sx={{marginLeft:"1em"}}>Update</Button>
              }
              </>
            }
          </div>
        </Typography>

        <div className="applicants_search-con flex items-center justify-center gap-4">
          <TextField
            label="Search BY FirstName, LastName,Registration ID, Local_Gov, Position"
            variant="outlined"
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            fullWidth
            margin="normal"
          />
         {/*  <FilterDialog /> */}
       
        </div>
    <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Full Name
                </TableCell>
              
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Local Government
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Ward
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Position
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  View
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Status
                </TableCell>
                 {
                      Checker !== null && 
                      <>
                       {
                           Checker === "Administrator" && 
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Remove
                </TableCell>
                       }
                      </>
                 }
              </TableRow>
            </TableHead>
            <TableBody>
               {
                ApplicantData === null && <div style={{width:"90%",height:"50%"}}  className=" text-center flex justify-center items-center absolute"><p>Loading Applicants List...</p></div>
               }
              {ApplicantData !== null && usersToShow.map((user, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.fname} 
                  </TableCell>
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.local_gov}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.applcnt_ward}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    {user.type_of_position_applied.slice(user.type_of_position_applied.indexOf("("))}
                    
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap",display:"flex", justifyContent:"center",alignItems:"center" }}>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      <ViewDialog data={user} />
                    </TableCell>
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                  
                     {(user.selected_applicant_status === 0 && user.awarded === 0) &&    <p className="bg-blue-950 text-white font-bold">Awaiting...</p>}
                     {(user.selected_applicant_status === 1 && user.awarded ===  0) &&   <p className="bg-green-950 text-white font-bold">Selected</p>}
                     {(user.selected_applicant_status === 2 && user.awarded === 0) &&   <p className="bg-red-950 text-white font-bold">Rejected</p>}
                     {(user.selected_applicant_status === 1 && user.awarded === 1 ) &&   <p className="bg-blue-400 text-white font-bold">Awarded</p>}
                    

                  </TableCell>
                  {
                    Checker !== null && <>
                      
                      {
                      Checker === "Administrator" && 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    <Button
                      className="Hover-"
                      onClick={() => HanldeRemove(user.user_token,user.picture,user.resume_file)}
                      elevation={10}
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: "rgb(199, 1, 1)",
                        margin: "9px 0px",
                      }}
                      size="small"
                      variant="contained"
                    >
                      <IconDelete
                        tooltipText="Remove Applicant"
                        tooltipColor="white"
                        tooltipIconSize="19px"
                      />
                    </Button>
                  </TableCell>
                      }
                    
                    </>
                  }

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> 
        {totalPages > 1 && (
          <div>
            {currentPage > 1 && (
              <Button
                className="Hover-"
                onClick={handlePrevPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px"
                }}
                size="large"
                variant="contained"
              >
                <ArrowBackIcon />
              </Button>
            )}
            <span>
              Page {currentPage} of {totalPages}
            </span>
            {currentPage < totalPages && (
              <Button
                className="Hover-"
                onClick={handleNextPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px",
                }}
                size="large"
                variant="contained"
              >
                <ArrowForwardIcon />
              </Button>
            )}
             <span className="ml-10">
              Total result is : { (rowsPerPage * totalPages).toLocaleString()} {}
            </span>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Applicants;
