import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import baseUrl from "../../../Route/BaseURL";
import axios from "axios";
import { ContextData } from "../../context/Context";

function AddLocalGovt() {


  const [formData, setFormData] = useState({
    localGovt: "",
  });

  const {accesstoken} = useContext(ContextData) 

  const [newLocalGovts, setNewLocalGovts] = useState(null);



  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const [Reload,setReload] = useState(true)



  const handleAdd = () => {
   

    // Check for empty fields
    const isEmptyField = Object.values(formData).every((value) => value === "");
 
    if (isEmptyField) {
      toast.warn("Please fill out all fields");
    } else {
        
      axios.post(`${baseUrl}/auth/add_local_gov`,formData,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
           'authorization': `Bearer ${accesstoken}`
        },
      })
      .then((res) => {
     
            toast.success(res.data)
            setReload(!Reload)
            setFormData({
              localGovt: "",
            })
      })
      .catch((err) => {
        if (err) return console.log(err.message);
      });

      
  }


  };


  const DeleteLocalGov = async(token) =>{


    try {
      const response = await axios.delete(`${baseUrl}/auth/delete_local_gov/${token}`, {
        withCredentials: true,
        headers: {
           'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      });
  
      toast.warn(response.data.statusText);
      setReload(!Reload)
    
    } catch (err) {
      if (err) console.log(err.message);
      // setLoader(false)
    }


  }


  useEffect(() => {

    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_local_gov`, {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
            'authorization': `Bearer ${accesstoken}`
          },
        });
    
        setNewLocalGovts(response.data);
      
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  },[Reload]); 


  

  return (
    <div className="add-localGovt">
      <form>
      <div className="input-con">
        <TextField
          label="Add Local Government"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.localGovt}
          onChange={handleInputChange("localGovt")}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                Add
              </Button>
            ),
          }}
        />
      </div>
      </form>
      
      <div className="list-con">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  No.
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Local Government
                </TableCell>

          {/*       <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Edit
                </TableCell> */}
                <TableCell
                  align="center"
                  sx={{ color: "white", fontWeight: "800" }}
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { newLocalGovts !== null && newLocalGovts.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                     {index + 1} 
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                   {item.add_local_gov}
                  </TableCell>
                 
                  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                    <Button
                      className="Hover-"
                      // onClick={() => HanldeRemove(user.st_tokens)}
                      elevation={10}
                      sx={{
                        whiteSpace: "nowrap",
                        backgroundColor: "rgb(199, 1, 1)",
                      }}
                      size="small"
                      variant="contained"
                      onClick={()=>DeleteLocalGov(item.tokens)}
                       >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AddLocalGovt;
