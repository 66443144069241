import React from "react";

function BankDetails(props) {
  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Bank Details </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        <ul>
            <li><b>Bank Name:</b> <span> {props.data.bank_name}</span></li>
            <li><b>Account Number:</b> <span> {props.data.account_number} </span></li>
            <li><b>Account Name:</b> <span> {props.data.account_name} </span></li>
        </ul>
      </div>

      
    </div>
  );
}

export default BankDetails;
