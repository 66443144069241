import { Button, InputLabel, TextField } from "@mui/material";
import React, { useState } from "react";

function SectionFour() {
  const [formData, setFormData] = useState({
    mainHeading: "",
    title: "",
    sectionHeading: "",
    sectionContent: "",
    image: "",
  });

  const handleInputChange = (field) => (e) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for empty fields
    const isEmptyField = Object.values(formData).every((value) => value === "");

    if (isEmptyField) {
      alert("Please fill out all fields");
    } else {
      console.log(formData);
    }
  };
  return (
    <div className="my-2 site-form-con">
      <form onSubmit={handleSubmit}>
        <div className="input-con">
          <TextField
            label="Main Heading"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            margin="normal"
            value={formData.mainHeading}
            onChange={handleInputChange("mainHeading")}
          />
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            margin="normal"
            value={formData.title}
            onChange={handleInputChange("title")}
          />
          <TextField
            label="Section Heading"
            variant="outlined"
            fullWidth
            multiline
            rows={2}
            margin="normal"
            value={formData.sectionHeading}
            onChange={handleInputChange("sectionHeading")}
          />
          <TextField
            label="Section Content"
            variant="outlined"
            fullWidth
            multiline
            rows={5}
            margin="normal"
            value={formData.sectionContent}
            onChange={handleInputChange("sectionContent")}
          />
          <InputLabel id="img">Upload Image</InputLabel>
          <TextField
            id="img"
            type="file"
            fullWidth
            variant="outlined"
            margin="normal"
            value={formData.image}
            onChange={handleInputChange("image")}
          />
          <Button variant="contained" color="primary">
            Publish
          </Button>
        </div>
      </form>
    </div>
  );
}

export default SectionFour;
