import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import axios from "axios";
import { ContextData } from "../../context/Context";
import AwardOptions from "./AwardOptions";
// import ApplicantProfileTabs from "../ApplicantProfile/ApplicantProfileTabs";
// import Avatar from "@mui/material/Avatar";

export default function AwardDialog(props) {



  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('xl');





  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [QualificationList, setQualificationList] = React.useState(null);
  const { AdministratorTokens, UpdateAmount } = React.useContext(ContextData);

  return (
    <div className="appicant-profile-dialog-con w-500rem bg-green-800 flex items-center justify-center">
      <Button variant="contained" onClick={handleClickOpen}>
         {props.type ==="approved" &&  "Export To Excel"}
         {props.type ==="notapproved" &&   "Award"}
         {props.type ==="sms" && "Send Bulk SMS"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="appicant-profile-dialog-con"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogContent >
          <AwardOptions type={props.type} />
        </DialogContent> 
        <DialogActions >
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
