import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import {
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";
import axios from "axios";
import { ContextData } from "../context/Context";
import baseUrl from "../../Route/BaseURL";
import AddUserDialog from "../Dashboard/AdminUser/AddAdminUserDialog";
import { toast } from "react-toastify";
/* import EditStudentPop from './PopDialogue/Student/EditStudentPop';
 */

const AdminUsers = () => {

  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [StudentData, setStudentData] = useState(null);
  const [CountData, setCountData] = useState(0);
  const rowsPerPage = 6;
  const { setLoader, accesstoken, RefreshUesFF,setRefreshUesFF } =
    useContext(ContextData);

  useEffect(() => {
    async function FetchData() {
      //  setLoader(true)

      try {
        const response = await axios.get(`${baseUrl}/auth/get_admin_users`, {
          withCredentials: true,
          headers: {
            'Accept': "application/json",
           'authorization': `Bearer ${accesstoken}`
          },
        });

        setCountData(Object.keys(response.data));
        setStudentData(response.data);
        setLoader(false);
      } catch (err) {
        if (err) console.log(err.message);
        // setLoader(false)
      }
    }
    FetchData();
  }, [RefreshUesFF]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const List = Object.keys(StudentData !== null && StudentData).map(
    (data) => StudentData[data]
  );
  const filteredUsers = List.filter((user) => {
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    return (
      (user.fname.toLowerCase().includes(lowerCaseSearchText) &&
        user.lname.toLowerCase().includes(lowerCaseSearchText)) ||
      user.mobile.toLowerCase().includes(lowerCaseSearchText) ||
      user.email.toLowerCase().includes(lowerCaseSearchText)
      // Add more fields for search if needed (e.g., state, phone number)
    );
  });

  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const usersToShow = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const HanldeRemove = async (sttokens) => {
    try {
      const response = await axios.delete(`${baseUrl}/auth/remove_admin_user/${sttokens}`,
        {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
           'authorization': `Bearer ${accesstoken}`
          },
        }
      );
      toast.success(response.data)
      setRefreshUesFF(!RefreshUesFF)
    } catch (err) {
      if (err) console.log(err.message);
    }
  };


  const HandleLock = async (token,value) => {
        const data = {token,value}
    try {
      const response = await axios.put(`${baseUrl}/auth/lock_account`,data,
        {
          withCredentials: true,
          headers: {
             'Accept': "application/json",
           'authorization': `Bearer ${accesstoken}`
          },
        }
      );
      toast.success(response.data)
      setRefreshUesFF(!RefreshUesFF)
    } catch (err) {
      if (err) console.log(err.message);
    }
  };




  return (
    <div className="con-dev" style={{ width: "100%", height: "100%" }}>
      <Container>
        <Typography
          variant="h4"
          gutterBottom
          className="flex justify-between items-center admin-users_header"
        >
          <p className="font-extrabold mt-3">Admin Users</p>
          <div className="admin-users_header-btn-con">
            <AddUserDialog />
          </div>
        </Typography>

        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          fullWidth
          margin="normal"
        />
        <div className="admin-users_table-con">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ background: "rgb(0, 24, 69)" }}>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Full Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Usertype
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Access Level
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Lock
                  </TableCell>
             
                  <TableCell
                    align="center"
                    sx={{ color: "white", fontWeight: "800" }}
                  >
                    Remove
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersToShow.map((user, index) => (
                  <TableRow key={user.id}>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {index + 1}
                    </TableCell>
                    {/*  <TableCell>
                  <Avatar sx={{boxShadow:'2px 2px 10px rgba(0,0,0,0.364)'}} alt={user.fname} src={`https://example.com/avatars/${user.id}.png`} />
                </TableCell> */}

                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.fname} {user.lname}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.email}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.mobile}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.usertype}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.acc_level}%
                    </TableCell>

                    {user.usertype === "Administrator" ? (
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        <Button
                          disabled={true}
                          className="Hover-"
                          elevation={10}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            backgroundColor: "rgb(179, 103, 21)",
                            margin: "9px 0px",
                          }}
                          size="large"
                          variant="contained"
                        >
                          Lock
                        </Button>
                      </TableCell>
                    ) : (
                      <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                        {user.acc_lock.toString() === "0" ? (
                          <Button
                            className="Hover-"
                            onClick={() => HandleLock(user.token,1)}
                            elevation={10}
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              backgroundColor: "rgb(199, 1, 1)",
                              margin: "9px 0px",
                            }}
                            size="large"
                            variant="contained"
                          >
                            Lock
                          </Button>
                        ) : (
                          <Button
                            className="Hover-"
                            onClick={() => HandleLock(user.token,0)}
                            elevation={10}
                            sx={{
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              backgroundColor: "rgb(179, 103, 21)",
                              margin: "9px 0px",
                            }}
                            size="large"
                            variant="contained"
                          >
                            Unlock
                          </Button>
                        )}
                      </TableCell>
                    )}

                   {/*  <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.insertDate.slice(user.insertDate.indexOf("T") + 1)}
                    </TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                      {user.insertDate.slice(0, user.insertDate.indexOf("T"))}
                    </TableCell>
 */}
                    <TableCell>
                      {user.usertype === "Administrator" ? (
                        <Button
                          disabled={true}
                          className="Hover-"
                          onClick={() => HanldeRemove(user.token)}
                          elevation={10}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            backgroundColor: "rgb(199, 1, 1)",
                            margin: "9px 0px",
                          }}
                          size="large"
                          variant="contained"
                        >
                          Remove
                        </Button>
                      ) : (
                        <Button
                          className="Hover-"
                          onClick={() => HanldeRemove(user.token)}
                          elevation={10}
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "14px",
                            backgroundColor: "rgb(199, 1, 1)",
                            margin: "9px 0px",
                          }}
                          size="large"
                          variant="contained"
                        >
                          Remove
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {totalPages > 1 && (
          <div>
            {currentPage > 1 && (
              <Button
                className="Hover-"
                onClick={handlePrevPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px",
                }}
                size="large"
                variant="contained"
              >
                <ArrowBackIcon />
              </Button>
            )}
            <span>
              Page {currentPage} of {totalPages}
            </span>
            {currentPage < totalPages && (
              <Button
                className="Hover-"
                onClick={handleNextPage}
                elevation={10}
                sx={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  backgroundColor: "rgb(0, 24, 69)",
                  color: "white",
                  margin: "9px 4px",
                }}
                size="large"
                variant="contained"
              >
                <ArrowForwardIcon />
              </Button>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export default AdminUsers;

// get_data_adminuser
