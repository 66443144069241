import React, { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import AwardBatchAccordion from "../Dashboard/Award/AwardBatchAccordion";
import { Button, IconButton, TextField } from "@mui/material";
import AwardDialog from "../Dashboard/Award/AwardDialog";
import { Outlet, useNavigate } from "react-router-dom";

function Site() {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const Navigate = useNavigate()

  

  const navItems = [
    {
      path: "hero-section",
      name: "Hero Section",
    },
    {
      path: "section-one",
      name: "Section One",
    },
    {
      path: "section-two",
      name: "Section Two",
    },
    {
      path: "section-three",
      name: "Section Three",
    },

    {
      path: "section-four",
      name: "Section Four",
    },
  ];

  return (
    <div className="award-flex-con w-full flex h-full">
      <div
        style={{ height: "" }}
        className="award w-1/5 bg-slate-100  overflow-auto"
      >
        <ul className="ver-aside-ul  ">
          {navItems.map((item, index) => (
            <li key={index} className="flex justify-between items-center"
            onClick={() => {
                Navigate(item.path);
              }} >
              <span className="p-3"> {item.name}</span>{" "}
            </li>
          ))}
        </ul>
      </div>

      <div style={{ height: "" }} className="batch w-4/5  overflow-auto">

        <Outlet />
      </div> 
    </div>
  );
}

export default Site;
