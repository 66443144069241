// import axios from 'axios'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import baseUrl from '../../Route/BaseURL';
import axios from 'axios';


export const ContextData = createContext()
      
function Context({children}) {


    



    const [Tokens,setTokens] = useState("")
    const [RefreshUesFF,setRefreshUesFF] = useState(true)
    const [UpdateAmount,setUpdateAmount] = useState("")
    const [readStatus,setreadStatus] = React.useState(true)
    const [ApplicantTokens,setApplicantTokens] = useState("")
    const [accesstoken,setaccesstoken]  = useState(null)
    const [Person,setPerson] = useState(null)
    const [PersonUser,setPersonUser] = useState(null)
    const [UserToken,setUserToken] = useState()
    const [ReloadBioForms,setReloadBioForms] = useState(false)
    const [BioProgressStatus,setBioProgressStatus] = useState(0)
    const [ParentProgressStatus,setParentProgressStatus] = useState(0)
    const [Checker,setChecker] = useState(null)
    const [ReloadPersonData,setReloadPersonData] = useState(true)
    const [UserSessionToken,setUserSessionToken] = useState("")
    const [CheckNull, setCheckNull] = useState("100vh")
    const [CheckEligibility, setCheckEligibility] = useState(false)
    const [BioChecker,setBioChecker]  = useState("")
    const [DemogrphChecker,setDemogrphChecker] = useState("")
    const [EduChecker,setEduChecker] = useState("")
    const [WrkExprnChecker,setWrkExprnChecker] = useState("")
    const [BankDetailsChecker,setBankDetailsChecker] = useState("")
    const [applcnStpsChecker,setapplcnStpsChecker] = useState("")
    const [Iam_Applying_for,setIam_Applying_for] = useState("")
    const [ApplicantData,setApplicantData] = useState(null);
    const [Count,setCount] = useState(0)
    const [formDataFilter, setFormDataFilter] = useState({
      localGovt: "",
      ward: "",
      unit: "",
      post: ""
    });
    const [ReloadApplicant_At_Filter,setReloadApplicant_At_Filter] = useState(true)
    const [ReloadAwardList,setReloadReloadAwardList] = useState(true)
    const  [CouuntAward,setCouuntAward] = useState(0)
    const [OpenAppstatus,setOpenAppstatus] = useState(null)

  // Fetch client Refresh Token

 

 


 // Get configroute for usertype to access the routes
 useEffect(()=>{


  async function FetchData(){
             
      try {
          
        const response = await axios.get(`${baseUrl}/auth/getconfig_route/${ApplicantTokens}`,{
          withCredentials: true,
           headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${accesstoken}`
          }
          } 
          ) 
     
          setChecker(response.data[0].usertype)
        //  sessionStorage.setItem('usertype',response.data[0].usertype);
        
        
     } catch (err) {
       if(err) console.log(err.message)
     }  
  
    }
    
    FetchData() 

 },[ApplicantTokens,accesstoken])
 


// Fetch Administrator Refresh Token
/* 
useEffect(()=>{
   
  // Fetch Administrator Refresh Token
  async function FetchData(){

  try {
  const response = await axios.get(`${baseUrl}/pub/clienttokens`,{
      withCredentials: true,
      headers: {
        'Accept' : 'application/json',
        'Content-Type': 'application/json',
          }
         })  
        
      // if(!response.data.status) return  setAdministratorTokens(null)
           
       if(response.data.status){
        setaccesstoken(response.data.accessToken)
        setUserToken(response.data.Usertoken)
      //  console.log(response.data.accesstoken)
       }

  } catch (err) {
  if(err) console.log(err.message)
  }
  }
  FetchData()

},[UserToken,accesstoken])
  */
 
useEffect(() => {
  async function FetchData() {
    //  setLoader(true)

    try {
      const response = await axios.get(`${baseUrl}/pub/get_open_application`, {
        withCredentials: true,
        headers: {
           'Accept': "application/json",
          'authorization': `Bearer ${accesstoken}`
        },
      });
  
      setOpenAppstatus(response.data[0].open_status);      
    
    } catch (err) {
      if (err) console.log(err.message);
      // setLoader(false)
    }
  }
  FetchData();
},[]); 

/* Get admin data */

useEffect(()=>{


  async function FetchData(){


    try { 
            
      const response = await axios.get(`${baseUrl}/auth/gets_single_admin_user/${ApplicantTokens}`,{
        withCredentials: true,
         headers: {
          'Accept' : 'application/json',
          'Content-Type': 'application/json',
          'authorization': `Bearer ${accesstoken}`
        }
        } 
        ) 
   
        setPersonUser(response.data[0])
    
   } catch (err) {
     if(err) console.log(err.message)
   }  
    
  }



FetchData() 

},[ApplicantTokens])


  return (
    <ContextData.Provider
    value={{
      Tokens,setTokens,
      RefreshUesFF,setRefreshUesFF,
      UpdateAmount,setUpdateAmount,
      readStatus,setreadStatus,
      ApplicantTokens,setApplicantTokens,
      ReloadBioForms,setReloadBioForms,
      BioProgressStatus,setBioProgressStatus,
      ParentProgressStatus,setParentProgressStatus,
      Checker,setChecker,
      accesstoken,setaccesstoken,
      Person,setPerson,
      ReloadPersonData,setReloadPersonData,
      CheckNull,setCheckNull,
      CheckEligibility, setCheckEligibility,
      BioChecker,setBioChecker,
      DemogrphChecker,setDemogrphChecker,
      EduChecker,setEduChecker,
      WrkExprnChecker,setWrkExprnChecker,
      BankDetailsChecker,setBankDetailsChecker,
      applcnStpsChecker,setapplcnStpsChecker,
      Iam_Applying_for,setIam_Applying_for,
      ApplicantData,setApplicantData,
      Count,setCount,
      formDataFilter,setFormDataFilter,
      ReloadApplicant_At_Filter,setReloadApplicant_At_Filter,
      ReloadAwardList,setReloadReloadAwardList,
      CouuntAward,setCouuntAward,
      OpenAppstatus,setOpenAppstatus,
      PersonUser,setPersonUser

     } }
    > 
    {children}
    </ContextData.Provider>
  )
}

export default Context