import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ApprovalIcon from "@mui/icons-material/Approval";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import LogoutIcon from "@mui/icons-material/Logout";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SignpostIcon from "@mui/icons-material/Signpost";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import SettingsIcon from "@mui/icons-material/Settings";
import LanguageIcon from "@mui/icons-material/Language";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { Button } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { ContextData } from "../context/Context";
import { toast } from "react-toastify";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function IndexMerchant() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [Changeroutes, setChangeroutes] = React.useState(null);
  const { Checker, setChecker, setApplicantTokens, setaccesstoken, Person } =
    React.useContext(ContextData);
  // const Sessionusertype = sessionStorage.getItem('usertype');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const Navigate = useNavigate();
  const usertype = ["applicant", "Administrator", "manage", "Electrol Officer"];

  React.useEffect(() => {
    // Checker !== null && Checker.usertype
    if (Checker !== null) {
      if (Checker === usertype[1]) {
        setChangeroutes([
          {
            path: "overseer",
            name: "Overseer",
            icon: <RemoveRedEyeIcon />,
          },
          {
            path: "admin-users",
            name: "Admin Users",
            icon: <SupervisorAccountIcon />,
          },

          {
            path: "applicants",
            name: "Applicants",
            icon: <ApprovalIcon />,
          },

          {
            path: "award",
            name: "Award",
            icon: <MilitaryTechIcon />,
          },
          {
            path: "post",
            name: "Post",
            icon: <SignpostIcon />,
          },
          {
            path: "account-helper",
            name: "Account Helper",
            icon: <AssignmentIndIcon />,
          },
          {
            path: "site",
            name: "Site",
            icon: <LanguageIcon />,
          },
        ]);

        return;
      }

      if (Checker === usertype[0]) {
        setChangeroutes([
          {
            path: "appllication_form",
            name: "Application Form",
            icon: <AccountCircleIcon />,
          },
          /*  {
               path: "award-letter",
               name: "Award Letter",
               icon: <LocalPostOfficeIcon />,
             }, */
        ]);
        return;
      }

      if (Checker === usertype[3]) {
        setChangeroutes([
          {
            path: "post",
            name: "Post",
            icon: <SignpostIcon />,
          },
        ]);
        return;
      }
    }
  }, [Checker]);

  const HandlelogOut = () => {
    //sessionStorage.removeItem('usertoken');
    //sessionStorage.removeItem('accessToken');
    //sessionStorage.clear();
    //  setApplicantTokens("")
    // setaccesstoken(null)
    Navigate("/");
  };

  return (
    <div
      className="dashboard-con"
      style={{ display: "flex", marginTop: "20px" }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            background: "green",
          }}
        >
          <span className="dashboard-aside_menu-icon">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </span>

          <Typography
            variant="h6"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            noWrap
            component="div"
          >
            {" "}
            <p className="flex items-center gap-4">
              {!open && (
                <span>
                  <img
                    src="/assets/siecom.png"
                    alt="item"
                    className="w-14 rounded-full"
                  />{" "}
                </span>
              )}
              Dashboard
            </p>
            {/*  {
              Checker !== null && Checker  === usertype[0] &&
            <p className="pl-1">
                ID : {Person !== null && Person.applicant[0].r_ID}
            </p>
            } */}
            <Button
              onClick={HandlelogOut}
              sx={{ background: "#888" }}
              variant="contained"
            >
              <span className="dashhboard-header_logout-text">Logout</span>
              <span className="dashhboard-header_logout-icon">
                <LogoutIcon />
              </span>
            </Button>
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            background: "green",
            height: "50px",
            color: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {open && (
            <img
              src="/assets/siecom.png"
              alt="item"
              className="w-14 rounded-full"
            />
          )}
          <h2>Menu</h2>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <div style={{ height: "80%", width: "100%" }}>
          <List>
            {Changeroutes !== null &&
              Changeroutes.map((list, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    title={list.name}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    onClick={() => {
                      Navigate(list.path);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {list.icon}
                    </ListItemIcon>

                    <ListItemText
                      primary={list.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </div>
        <Divider />
        {Checker !== null && (
          <>
            {Checker === "Administrator" && (
              <div style={{ height: "20%", width: "100%" }}>
                <List>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        Navigate("settings");
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SettingsIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary="Settings"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        Navigate("audit");
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <VerifiedUserIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary="Audit"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </div>
            )}
          </>
        )}
             {Checker !== null && (
          <>
            {Checker !== "Administrator" && (
              <div style={{ height: "20%", width: "100%" }}>
                <List>
                  <ListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                      onClick={() => {
                        Navigate("settings");
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <SettingsIcon />
                      </ListItemIcon>

                      <ListItemText
                        primary="Settings"
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                 
                </List>
              </div>
            )}
          </>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, marginTop: "2.5rem" }}>
        <Outlet />
      </Box>
    </div>
  );
}
