import * as React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

export default function IconView(props) {
  return (
    <Tooltip title={props.tooltipText}>
      <IconButton>
        <VisibilityIcon  sx={{color:props.tooltipColor, fontSize:props.tooltipIconSize}}  />
      </IconButton>
    </Tooltip>
  );
}
