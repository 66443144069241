import React from 'react'
import Nav from './Nav'
import Hero from './Hero'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'
import SectionThree from './SectionThree'
import SectionFour from './SectionFour'
import Footer from './Footer'
import SearchSection from './SearchSection'
import PositionsCard from './PositionsCard'

function Home() {
  return (
    <div className='home'>
      <Nav />
      <Hero />
      <SearchSection />
      <SectionOne />
      <PositionsCard />
     {/*  <SectionTwo />
      <SectionThree />
      <SectionFour /> */}
      <Footer />
    </div>
  )
}

export default Home
