import React from "react";

function BioData(props) {
  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Demography </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        
        <ul>
            <li><b>State Of Origin:</b> <span>{props.data.applcnt_state_of_origin} </span></li>
            <li><b>LGA Of Residence:</b> <span>{props.data.local_gov}</span></li>
            <li><b>Ward Of Residence:</b> <span>{props.data.applcnt_ward}</span></li>
            <li><b>Contact Address:</b> <span>{props.data.applcnt_address}</span></li>

        </ul>

      </div>

      
    </div>
  );
}

export default BioData;
