import React from "react";
import { Button } from "@mui/material";
import baseUrl from "../../../Route/BaseURL";






function BioData(props) {


  return (
    <div className="bioData-con">
        <h3 className="bg-blue-950 text-white  mb-3 mt-2 text-center text-3xl">
            <b> Education </b>
        </h3>

      <div className="bg-slate-50 p-4 applcnt-pro-bioData-inner-con rounded-lg">
        
        <ul>
            <li><b>Highest Educational Qualification:</b> <span> {props.data.highst_edu} </span></li>
            <li className="mt-2"><b>View Qualification:</b> <span>   <a className="p-2 bg-green-950 text-cyan-200 font-bold rounded-xl " target="_blank" href={`${baseUrl}/get_file/${props.data.resume_file}`}>View Certificate</a> </span></li>
           

        </ul>

      </div>

      
    </div>
  );
}




export default BioData;
