import { Button } from "@mui/material";
import React from "react";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useNavigate } from "react-router-dom";

function ApplicantLandPage() {

      const Navigate = useNavigate()


  return (
    <div className="h-screen flex items-center justify-center">
      <Button onClick={()=> Navigate("/application/appllication_form")} variant="contained">Click to Continue Application</Button>
    </div>
  );
}

export default ApplicantLandPage;
